import isObject from 'lodash/isObject';
import map from 'lodash/map';
import isString from 'lodash/isString';
import { stopSubmit, touch } from 'redux-form';
import { put } from 'redux-saga/effects';

import { WIDGET_SET_MODAL_ERROR, WIDGET_SET_SUBMITTING_STATUS } from 'actionTypes';
import { IActionMeta } from 'types/common';

export interface IFormSubmissionErrorParams {
  payload: Record<string, unknown> | string;
  meta: IActionMeta;
  form: string;
}

export default function* formSubmissionError({ payload, meta, form }: IFormSubmissionErrorParams) {
  if (isObject(payload)) {
    const wrongFields = map(payload, (value, key) => key);

    yield put(touch(form, ...wrongFields));
    yield put(stopSubmit(form, payload));
  } else if (isString(payload)) {
    yield put(stopSubmit(form, { _error: payload }));
  }

  yield put({ type: WIDGET_SET_MODAL_ERROR, meta, payload });
  yield put({ type: WIDGET_SET_SUBMITTING_STATUS, payload: false, meta });
}
