import { useEffect, useState } from 'react';
import filter from 'lodash/filter';
import get from 'lodash/get';
import difference from 'lodash/difference';
import uniq from 'lodash/uniq';
import { Dispatch } from 'redux';

import { setUnusedFields } from 'actions/form';
import { IFormField } from 'types/form';

export interface IUseFilterFormFieldsParams {
  fields: IFormField[];
  formName: string;
  dispatch: Dispatch;
}

const useFilterFormFields = ({ fields, formName, dispatch }: IUseFilterFormFieldsParams): IFormField[] => {
  const [filteredFields, setFilteredFields] = useState<IFormField[]>([]);

  useEffect(() => {
    const usedFields: string[] = [];
    const unusedFields: string[] = [];

    const filtered = filter(fields, (field) => {
      const name = get(field, `name`);
      const hide = get(field, `hide`, false);
      const unused = get(field, `unused`, false);

      if (formName) {
        if (unused) {
          unusedFields.push(name);
        } else {
          usedFields.push(name);
        }
      }

      return !hide;
    });

    const uniqUnusedFields = uniq(difference(unusedFields, usedFields));
    dispatch(setUnusedFields(uniqUnusedFields, { form: formName }));

    setFilteredFields(filtered);
  }, [fields]);

  return filteredFields;
};

export default useFilterFormFields;
