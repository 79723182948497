import React from 'react';
import map from 'lodash/map';

export interface IPasswordViewProps {
  password?: string | null;
}

const PasswordView = ({ password }: IPasswordViewProps) => <span>{map(password, () => `•`)}</span>;

export default PasswordView;
