import { select } from 'redux-saga/effects';
import { getFormError, getFormSyncErrors, isValid } from 'redux-form';

import formSubmissionError from 'sagas/effects/formSubmissionError';
import { IActionMeta } from 'types/common';

interface IParams {
  form: string;
  meta: IActionMeta;
}

// Todo: get rid of @ts-ignore
function* validateForm({ form, meta }: IParams): any {
  // @ts-ignore
  const valid = yield select(isValid(form));
  // @ts-ignore
  const syncErrors = yield select(getFormSyncErrors(form)) || {};
  // @ts-ignore
  const formError = yield select(getFormError(form));
  if (!valid) {
    const errors = { ...syncErrors };
    if (formError) {
      errors._error = formError;
    }
    yield formSubmissionError({ payload: errors, meta, form });
  }

  return valid;
}

export default validateForm;
