import React, { ChangeEvent } from 'react';

export interface ITableCheckbox {
  id: string | number;
  checked: boolean;
  onChange: (val: ChangeEvent<HTMLInputElement>) => void;

  disabled?: boolean;
}

const TableCheckbox = ({ id, checked, disabled, onChange }: ITableCheckbox) => {
  return (
    <label
      className="checkbox-group__label css-control css-control-success css-switch css-control-sm"
      id={`${id}_checkbox_wrap`}
    >
      <input
        type="checkbox"
        className="css-control-input"
        id={`${id}_checkbox`}
        checked={checked}
        disabled={disabled}
        onChange={onChange}
      />
      <span className="css-control-indicator" />
    </label>
  );
};

TableCheckbox.defaultProps = {
  disabled: false,
};

export default TableCheckbox;
