import React, { ComponentType, ReactNode, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import map from 'lodash/map';
import isEqual from 'lodash/isEqual';
import classNames from 'classnames';
import { FieldArrayFieldsProps } from 'redux-form';

import { IFormField } from 'types/form';

export interface IArrayFieldsParams {
  fields: (value: any, index?: number) => IFormField[];
  title: string;
  disableCallback?: (fields: FieldArrayFieldsProps<any>) => boolean;
  disabled?: boolean;
  wrapClassName?: string;
  name: string;
  FormField: ComponentType<IFormField>;
  showAddButton?: boolean;
  showDeleteButtons?: boolean;
  hint?: string;
  showHint?: boolean;
  state?: any;
}

interface IResultCallbackParams {
  fields: FieldArrayFieldsProps<any>;
}

export type ArrayFieldsResult = (params: IResultCallbackParams) => ReactNode;

const useArrayFields = ({
  fields: inputs,
  title,
  wrapClassName,
  disabled,
  name,
  disableCallback,
  FormField,
  showAddButton = true,
  showDeleteButtons = true,
  showHint = false,
  hint = ``,
  state = {},
}: IArrayFieldsParams): ArrayFieldsResult => {
  const [t] = useTranslation();

  const [prevInputs, setPrevInputs] = useState<IFormField[]>([]);

  useEffect(() => {
    if (!isEqual(prevInputs, inputs({}))) {
      setPrevInputs(inputs);
    }
  }, [inputs]);

  const allErrors = state?.syncErrors || {};
  const fieldsErrors = allErrors[name] || [];
  const arrError = fieldsErrors[0]?._error || ``;

  return useCallback(
    ({ fields }) => {
      let creatingBtnIsDisabled = disabled;
      if (!creatingBtnIsDisabled && disableCallback) {
        creatingBtnIsDisabled = disableCallback(fields);
      }

      return (
        <div className="mb-15">
          <div className="flex space-between align-items-center mb-10">
            <span className="font-weight-bold">{t(title)}</span>
            {showAddButton && (
              <button
                className="btn btn-alt-success"
                type="button"
                disabled={creatingBtnIsDisabled}
                id={`${name}_add_btn`}
                onClick={() => fields.push({})}
              >
                {t(`common.add`)}
              </button>
            )}
          </div>
          {!!arrError && <span className="text-error">{arrError}</span>}
          {showHint && hint && <i>{hint}</i>}
          {fields.map((field, index) => {
            return (
              <div key={index} className={classNames(wrapClassName, `flex space-between mb-10 align-items-start`)}>
                {map(inputs(fields.get(index), index), (input, key) => {
                  return (
                    <FormField {...input} arrayField key={key} formGroup={false} name={`${field}.${input.name}`} />
                  );
                })}
                {showDeleteButtons && (
                  <button
                    className="ml-10"
                    type="button"
                    id={`${name}_${index}_remove_btn`}
                    onClick={() => {
                      fields.remove(index);
                    }}
                  >
                    <i className="fa fa-close text-danger font-size-xl mt-7" />
                  </button>
                )}
              </div>
            );
          })}
        </div>
      );
    },
    [t, prevInputs, disabled, showHint, arrError]
  );
};

export default useArrayFields;
