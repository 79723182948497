import React from 'react';

import { Size } from 'utils/enums';
import { ISubmissionFormButtonProps } from 'types/form';

import Preloader from 'components/base/Preloader';

const FormSubmissionButton = ({ children, submitting, disabled, ...props }: ISubmissionFormButtonProps) => (
  <div className="flex">
    <button className="btn btn-alt-primary" disabled={submitting || disabled} {...props}>
      {children}
    </button>
    {submitting && <Preloader center={false} className="margin-mini-preloader" size={Size.LITTLE} />}
  </div>
);

FormSubmissionButton.defaultProps = {
  submitting: false,
  disabled: false,
};

export default FormSubmissionButton;
