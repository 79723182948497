import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import get from 'lodash/get';

import { IModalProps } from 'types/common';

import ConfirmationModal from 'components/modals/ConfirmationModal';

export interface IRemovingModalProps extends IModalProps {
  content?: string;
  confirmText?: string;
  removeMultipleItems: boolean;
}

const RemovingModal = ({
  content,
  confirmText,
  actionCreators,
  modal,
  dispatch,
  removeMultipleItems,
  ...props
}: IRemovingModalProps) => {
  const [t] = useTranslation();

  const onRemove = useCallback(() => {
    if (removeMultipleItems) {
      dispatch(actionCreators.removeMultipleItems(get(modal, `list`)));
    } else {
      dispatch(actionCreators.remove(get(modal, `id`)));
    }
  }, [dispatch, actionCreators, removeMultipleItems, modal]);

  if (!content) {
    content = t(`widgets.doYouReallyWantToDeleteThisItem`);
  }

  if (!confirmText) {
    confirmText = t(`common.delete`);
  }

  return (
    <ConfirmationModal
      {...props}
      dispatch={dispatch}
      onConfirm={onRemove}
      modal={modal}
      content={content as string}
      actionCreators={actionCreators}
      confirmText={confirmText as string}
      confirmClassName="btn-alt-danger"
    />
  );
};

export default RemovingModal;
