import {
  AUTH_CHECK_REQUESTED,
  LOGIN_REQUESTED,
  LOGOUT_REQUESTED,
  REFRESH_TOKEN_REQUESTED,
  SET_LANG,
  SET_TABLES_PER_PAGE,
  UPDATE_HIDDEN_COLUMN_REQUESTED,
  UPDATE_HIDDEN_COLUMN_SUCCEEDED,
  INIT_APP_REQUESTED,
} from 'actionTypes';
import { IHideColumnsPayload } from 'types/auth';
import { IAction } from 'types/common';
import { IConfigState } from 'types/config';

export const setLang = (payload: string) => ({ type: SET_LANG, payload });

export const authCheckRequest = () => ({ type: AUTH_CHECK_REQUESTED });

export const loginRequest = (payload: any) => ({ type: LOGIN_REQUESTED, payload });

export const refreshTokenRequest = (promise: any) => ({ type: REFRESH_TOKEN_REQUESTED, payload: promise });

export const logoutRequest = () => ({ type: LOGOUT_REQUESTED });

export const setTablesPerPage = (payload: number | null) => ({ type: SET_TABLES_PER_PAGE, payload });

export const updateHiddenColumnRequested = (payload: IHideColumnsPayload): IAction<IHideColumnsPayload> => ({
  type: UPDATE_HIDDEN_COLUMN_REQUESTED,
  payload,
});

export const updateHiddenColumnSucceeded = (payload: IHideColumnsPayload): IAction<IHideColumnsPayload> => ({
  type: UPDATE_HIDDEN_COLUMN_SUCCEEDED,
  payload,
});

export const initApp = (payload: IConfigState): IAction<IConfigState> => ({ type: INIT_APP_REQUESTED, payload });
