import React from 'react';

import { ISubmissionFormButtonProps } from 'types/form';

import FormSubmissionButton from 'components/form/buttons/FormSubmissionButton';

const ModalFormSubmit = ({ children, submitting, disabled, ...props }: ISubmissionFormButtonProps) => (
  <div className="form-group pt-10 row justify-content-end">
    <div className="col-lg-8">
      <FormSubmissionButton submitting={submitting} disabled={disabled} {...props}>
        {children}
      </FormSubmissionButton>
    </div>
  </div>
);

ModalFormSubmit.defaultProps = {
  submitting: false,
  disabled: false,
};

export default ModalFormSubmit;
