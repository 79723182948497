import React, { useMemo } from 'react';
import useDesktopSize from 'hooks/useDesktopSize';
import isArray from 'lodash/isArray';
import map from 'lodash/map';
import size from 'lodash/size';
import isObject from 'lodash/isObject';
import filter from 'lodash/filter';
import { useTranslation } from 'react-i18next';

import { Size } from 'utils/enums';
import { IWidgetTableProps, IWidgetTitles } from 'types/widgets';

import Dropdown from 'components/base/Dropdown';
import Preloader from 'components/base/Preloader';
import TableColumnHideForm from 'components/tables/TableColumnHideForm';
import Tooltip from 'components/base/Tooltip';

const WidgetTable = ({
  widget,
  data,
  titles,
  listMapping,
  paginationLoading = false,
  lastPage,
  page,
  perPage,
  actionCreators,
  loading,
  withPagination = false,
  hiddenColumns,
  dispatch,
  location,
  paginatorValues,
  search,
  FormField,
  ReduxFormPaginator,
  showCellFilters = true,
}: IWidgetTableProps) => {
  const desktopSize = useDesktopSize();

  const [t] = useTranslation();

  const activeTitles = useMemo<IWidgetTitles[]>(() => {
    return filter(
      titles,
      (item) => item && !item.hide && (desktopSize ? item.desktop : item.mobile)
    ) as IWidgetTitles[];
  }, [desktopSize, titles]);

  const checkBoxTitles = useMemo(() => {
    return filter(activeTitles, (item) => !item.disabled);
  }, [activeTitles]);

  const availableTitles = useMemo<IWidgetTitles[]>(() => {
    return filter(activeTitles, (item) => !hiddenColumns.includes(item.id)) as IWidgetTitles[];
  }, [hiddenColumns, activeTitles]);

  if (!isArray(data)) {
    return null;
  }

  let table,
    emptyList = false;
  if (size(data) === 0) {
    emptyList = true;
    const text = page > 1 ? `widgets.dataOnRequestedPageIsNotAvailable` : `widgets.listIsEmpty`;
    table = <span className="table-content__message">{t(text)}</span>;
  } else {
    table = (
      <table className="table table-vcenter">
        {desktopSize && (
          <thead>
            <tr>
              {map(availableTitles, ({ title, className = ``, description }, key) => {
                let titleField;
                if (description) {
                  titleField = (
                    <>
                      <span className="mr-10">{t(title)}</span>
                      <Tooltip insideContent={<span>{t(description)}</span>}>
                        <i className="fa fa-question-circle-o" />
                      </Tooltip>
                    </>
                  );
                } else {
                  titleField = t(title);
                }

                return (
                  <th key={key} className={className}>
                    {titleField}
                  </th>
                );
              })}
            </tr>
          </thead>
        )}
        <tbody>
          {map(data, (item, key) => {
            if (isObject(item)) {
              item = { ...item, desktopSize, hiddenColumns };
            } else {
              item = { item, desktopSize, hiddenColumns };
            }

            return listMapping(item, key);
          })}
          {paginationLoading && (
            <tr>
              <td colSpan={size(availableTitles)}>
                <Preloader size={Size.LITTLE} />
              </td>
            </tr>
          )}
        </tbody>
      </table>
    );
  }

  const cellFilters = (
    <div className="table-column-filter">
      <span>{t(`widgets.displayCells`)}: </span>
      {checkBoxTitles.length > 0 ? (
        <Dropdown
          insideContent={() => (
            <div>
              <TableColumnHideForm
                hiddenColumns={hiddenColumns}
                dispatch={dispatch}
                widget={widget}
                columns={map(checkBoxTitles, ({ title, id, disabled }) => ({
                  text: t(title),
                  value: id,
                  disabled,
                }))}
              />
            </div>
          )}
        >
          <span className="mr-5">{t(`common.all`)}</span>
          <i className="fa fa-caret-down" />
        </Dropdown>
      ) : (
        <span className="disabled">
          <span className="mr-5">{t(`common.all`)}</span>
          <i className="fa fa-caret-down" />
        </span>
      )}
    </div>
  );

  return (
    <>
      {showCellFilters && cellFilters}
      {withPagination && (
        <ReduxFormPaginator
          FormField={FormField}
          pageCount={lastPage}
          page={page}
          perPage={perPage}
          actionCreators={actionCreators}
          loading={loading}
          dispatch={dispatch}
          // @ts-ignore
          location={location}
          paginatorValues={paginatorValues}
          search={search}
        />
      )}
      <div className="table-responsive">{table}</div>
      {withPagination && !emptyList && (
        <ReduxFormPaginator
          FormField={FormField}
          pageCount={lastPage}
          page={page}
          perPage={perPage}
          actionCreators={actionCreators}
          loading={loading}
          dispatch={dispatch}
          // @ts-ignore
          location={location}
          paginatorValues={paginatorValues}
          search={search}
        />
      )}
    </>
  );
};

export default WidgetTable;
