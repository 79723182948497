export const CRYPTOCURRENCY_PRECISION = 8;

export const STRICT_LATIN_DIGITS_AND_SYMBOLS_LOWER_REGEX = /([^a-zA-Z0-9])+/g;
export const LATIN_DIGITS_AND_SYMBOLS_REGEX = /([^a-z^A-Z^0-9\u0021-\u002F\u003A-\u0040\u005B-\u0060\007B-\u007E])+/g;
export const LATIN_DIGITS_AND_SYMBOLS_LOWER_REGEX = /([^a-z0-9_])+/g;
export const LATIN_DIGITS_AND_SYMBOLS_WITH_SPACE_REGEX = /([^a-zA-Z0-9\s])+/g;

export const MODAL_FADING_TIMEOUT = 150;

export const SCROLL_PAGINATION_LIMIT = 60;
export const DEFAULT_PAGINATION_LIMIT = 100;

export const DESKTOP_SIZE = 992;

export const DEFAULT_LANG = `ru`;

// Form names

export const LOGIN_FORM_NAME = `login`;
export const PAGINATOR_FORM_NAME = `paginator`;
