import { DEFAULT_LANG } from 'utils/constants';

const systemLang =
  (availableLangs: Set<string>) =>
  (lang: string | null = null) => {
    if (!lang) {
      lang = navigator.language.substring(0, 2);
    }

    return availableLangs.has(lang) ? lang : DEFAULT_LANG;
  };

export default systemLang;
