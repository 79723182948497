import React, { ReactNode } from 'react';
import MaterialUIButton from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { ButtonTypeMap } from '@material-ui/core/Button/Button';

export interface IButtonProps extends ButtonTypeMap {
  children: ReactNode;
  submitting?: boolean;
  disabled?: boolean;
}

const Button = ({ children, submitting, disabled, ...props }: IButtonProps) => (
  <MaterialUIButton
    type="submit"
    variant="contained"
    disabled={submitting || disabled}
    endIcon={<>{submitting && <CircularProgress color="inherit" size={20} />}</>}
    {...props}
  >
    {children}
  </MaterialUIButton>
);

Button.defaultProps = {
  submitting: false,
  disabled: false,
  color: `primary`,
};

export default Button;
