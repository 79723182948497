import i18n from '../i18n';

const validateMessages = () => ({
  requiredField: i18n.t(`errors.requiredField`),
  invalidEmail: i18n.t(`errors.invalidEmail`),
  invalidAmount: i18n.t(`errors.invalidAmount`),
  onlyLatinDigitsAndSymbols: i18n.t(`errors.onlyLatinDigitsAndSymbols`),
  invalidCardFormat: i18n.t(`errors.invalidCardFormat`),
  invalidPhoneFormat: (len: number, prefix: string): string => i18n.t(`errors.invalidPhoneFormat`, { len, prefix }),
  mustBeDifferentValues: i18n.t(`errors.mustBeDifferentValues`),

  invalidDate: i18n.t(`errors.invalidDate`),
  bothDateRequired: i18n.t(`errors.bothDateRequired`),

  invalidUrl: i18n.t(`errors.invalidUrl`),
  invalidHttpsUrl: i18n.t(`errors.invalidHttpsUrl`),
  invalidIpFormat: i18n.t(`errors.invalidIpFormat`),
  passwordMustNotContainSpaces: i18n.t(`errors.passwordMustNotContainSpaces`),

  minFieldSize: (len: number): string => i18n.t(`errors.minFieldSize`, { len }),
  maxFieldSize: (len: number): string => i18n.t(`errors.maxFieldSize`, { len }),

  minAmount: (amount: number): string => i18n.t(`errors.minAmount`, { amount }),
  maxAmount: (amount: number): string => i18n.t(`errors.maxAmount`, { amount }),
  minAmountShouldBeGreater: (amount: number): string => i18n.t(`errors.minAmountShouldBeGreater`, { amount }),

  setValueGreaterThan0ForThisPayment: i18n.t(`errors.setValueGreaterThan0ForThisPayment`),
  setValueRange: i18n.t(`errors.setValueRange`),

  maxDepositMustBeGreaterThanMin: i18n.t(`errors.maxDepositMustBeGreaterThanMin`),
  maxWithdrawalMustBeGreaterThanMin: i18n.t(`errors.maxWithdrawalMustBeGreaterThanMin`),

  invalidString: i18n.t(`errors.invalidCookieString`),

  invalidEnglishName: i18n.t(`errors.invalidEnglishName`),
  invalidEnglishCode: i18n.t(`errors.invalidEnglishCode`),

  providerError: i18n.t(`errors.providerError`),
});

export default validateMessages;
