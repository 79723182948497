import { Reducer } from 'redux';
import map from 'lodash/map';
import filter from 'lodash/filter';
import get from 'lodash/get';
import find from 'lodash/find';
import isArray from 'lodash/isArray';
import isObject from 'lodash/isObject';
import uniq from 'lodash/uniq';
import isEmpty from 'lodash/isEmpty';

import {
  WIDGET_RESET_ERROR,
  WIDGET_RESET_MODAL,
  WIDGET_SET_ERROR,
  WIDGET_SET_MODAL,
  WIDGET_CREATE_REQUESTED,
  WIDGET_CREATE_SUCCEEDED,
  WIDGET_LIST_REQUESTED,
  WIDGET_LIST_SUCCEEDED,
  WIDGET_REMOVE_REQUESTED,
  WIDGET_REMOVE_SUCCEEDED,
  WIDGET_LIST_ITEM_UPDATE_REQUESTED,
  WIDGET_LIST_ITEM_UPDATE_SUCCEEDED,
  WIDGET_SET_PAGE,
  WIDGET_ITEM_REQUESTED,
  WIDGET_ITEM_SUCCEEDED,
  WIDGET_LIST_FAILED,
  WIDGET_ITEM_FAILED,
  WIDGET_SET_MODAL_ERROR,
  WIDGET_RESET_MODAL_ERROR,
  WIDGET_SET_SUBMITTING_STATUS,
  WIDGET_LIST_RESET,
  WIDGET_SET_ANY_DATA,
  WIDGET_SET_ITEM,
  WIDGET_SYNC_REMOVE,
  WIDGET_ITEM_RESET,
  WIDGET_SELECT_ITEM,
  WIDGET_UNSELECT_ITEM,
  WIDGET_REMOVE_MULTIPLE_ITEMS_SUCCEEDED,
  WIDGET_RESET_SELECT_LIST,
} from 'actionTypes';

import { IRootWidgetsState, IWidgetsState } from 'types/widgets';
import { IWidgetsAction } from 'types/widgets';

const widgetActions = new Set([
  WIDGET_LIST_REQUESTED,
  WIDGET_LIST_SUCCEEDED,
  WIDGET_LIST_FAILED,
  WIDGET_LIST_RESET,
  WIDGET_SET_MODAL,
  WIDGET_RESET_MODAL,
  WIDGET_SET_ERROR,
  WIDGET_RESET_ERROR,
  WIDGET_CREATE_REQUESTED,
  WIDGET_CREATE_SUCCEEDED,
  WIDGET_REMOVE_REQUESTED,
  WIDGET_REMOVE_SUCCEEDED,
  WIDGET_SYNC_REMOVE,
  WIDGET_LIST_ITEM_UPDATE_REQUESTED,
  WIDGET_LIST_ITEM_UPDATE_SUCCEEDED,
  WIDGET_SET_PAGE,
  WIDGET_ITEM_REQUESTED,
  WIDGET_ITEM_SUCCEEDED,
  WIDGET_ITEM_FAILED,
  WIDGET_SET_ITEM,
  WIDGET_SET_MODAL_ERROR,
  WIDGET_SET_SUBMITTING_STATUS,
  WIDGET_RESET_MODAL_ERROR,
  WIDGET_SELECT_ITEM,
  WIDGET_UNSELECT_ITEM,
  WIDGET_SET_ANY_DATA,
  WIDGET_REMOVE_MULTIPLE_ITEMS_SUCCEEDED,
  WIDGET_RESET_SELECT_LIST,
]);

export const widgetDefaultState: IWidgetsState = {
  items: null,
  item: null,
  listLoading: false,
  itemLoading: false,
  error: null,
  modalError: null,
  modal: null,
  page: 1,
  selectedItems: new Set(),
  nextPage: false,
  paginationLoading: false,
  submitting: false,
  lastPage: 1,
};
// @ts-ignore
export const widgetReducer: Reducer<IWidgetsState, IWidgetsAction> = (
  state = widgetDefaultState,
  { type, payload = {}, meta = {} }
) => {
  const incPage = meta?.incPage;
  const listLabel = meta?.labels?.listLabel;
  const itemLabel = meta?.labels?.itemLabel;
  const withPagination = meta?.withPagination;
  const getExtraListPayload = meta?.getExtraListPayload;
  const uniqKey = meta?.uniqKey || `id`;
  const uniqList = meta?.uniqList || false;
  const itemIsUniqKey = meta?.itemIsUniqKey || false;

  switch (type) {
    case WIDGET_LIST_REQUESTED:
      return { ...state, listLoading: true, paginationLoading: incPage };
    case WIDGET_LIST_SUCCEEDED:
      const extraListPayload = getExtraListPayload ? getExtraListPayload(payload) : {};

      if (withPagination) {
        // Todo: в суперадминке другой формат пагинации. Найти места и попросить сагана переделать формат.
        const data = get(payload, `${listLabel}.data`);
        const lastPage = get(payload, `${listLabel}.last_available_page`);
        const currentPage = get(payload, `${listLabel}.current_page`);

        return {
          ...state,
          ...extraListPayload,
          items: incPage && isArray(data) ? [...(state?.items || []), ...data] : data,
          page: currentPage,
          nextPage: currentPage < lastPage,
          paginationLoading: false,
          listLoading: false,
          lastPage,
        };
      }

      return {
        ...state,
        ...extraListPayload,
        listLoading: false,
        items: get(payload, listLabel as string, null),
      };
    case WIDGET_LIST_RESET:
      return { ...state, items: null, nextPage: false, page: 1, paginationLoading: false, createdItems: null };
    case WIDGET_ITEM_RESET:
      return { ...state, item: null, itemError: null };
    case WIDGET_LIST_FAILED:
      return { ...state, error: payload, listLoading: false, paginationLoading: false };
    case WIDGET_ITEM_REQUESTED:
      return { ...state, itemLoading: true, item: null };
    case WIDGET_ITEM_SUCCEEDED:
      return { ...state, itemLoading: false, item: get(payload, itemLabel as string, null) };
    case WIDGET_SET_ITEM:
      return { ...state, item: find(state.items, (item) => get(item, `id`) === payload) || null };
    case WIDGET_ITEM_FAILED:
      return { ...state, itemError: payload, itemLoading: false };
    case WIDGET_SET_MODAL:
      return { ...state, modal: { ...payload, submitting: false } };
    case WIDGET_SET_SUBMITTING_STATUS:
      return { ...state, submitting: payload };
    case WIDGET_RESET_MODAL:
      return { ...state, modal: null, modalError: null };
    case WIDGET_SET_ERROR:
      return { ...state, error: payload };
    case WIDGET_RESET_ERROR:
      return { ...state, error: null };
    case WIDGET_SET_MODAL_ERROR:
      return { ...state, modalError: payload };
    case WIDGET_RESET_MODAL_ERROR:
      return { ...state, modalError: null };
    case WIDGET_CREATE_SUCCEEDED: {
      const createdItem = get(payload, itemLabel as string);
      const id = get(createdItem, uniqKey);

      const createdItems = isArray(state?.createdItems) ? [...state.createdItems, id] : [id];

      if (isArray(createdItem)) {
        let items = state.items ? [...createdItem, ...state.items] : [...createdItem];
        if (uniqList && itemIsUniqKey) {
          items = uniq(items);
        }

        return {
          ...state,
          items,
          createdItems,
        };
      } else if (createdItem) {
        return { ...state, items: state.items ? [createdItem, ...state.items] : [createdItem], createdItems };
      }

      return { ...state, error: `Creating item failed. item is not defined.` };
    }
    case WIDGET_LIST_ITEM_UPDATE_SUCCEEDED: {
      const id = get(payload, `${itemLabel}.id`);
      const updatedItem = get(payload, itemLabel as string);

      if (!isEmpty(updatedItem)) {
        return { ...state, items: map(state.items, (item) => (item.id === id ? { ...item, ...updatedItem } : item)) };
      }

      return { ...state, error: `Updating item failed. id is not defined.` };
    }
    case WIDGET_REMOVE_SUCCEEDED:
    case WIDGET_SYNC_REMOVE:
      if (itemIsUniqKey) {
        return {
          ...state,
          items: filter(state.items, (item) => item !== payload),
        };
      }

      return {
        ...state,
        items: filter(state.items, (item) => item[uniqKey] !== payload),
      };
    case WIDGET_SET_PAGE:
      return { ...state, page: payload };
    case WIDGET_RESET_SELECT_LIST:
      return { ...state, selectedItems: new Set() };
    case WIDGET_SELECT_ITEM:
      return { ...state, selectedItems: new Set(state.selectedItems).add(payload) };
    case WIDGET_UNSELECT_ITEM:
      const selectedItems = new Set(state.selectedItems);
      selectedItems.delete(payload);

      return { ...state, selectedItems };
    case WIDGET_REMOVE_MULTIPLE_ITEMS_SUCCEEDED:
      if (itemIsUniqKey) {
        return {
          ...state,
          items: filter(state.items, (item) => !payload.has(item)),
        };
      }

      return {
        ...state,
        items: filter(state.items, (item) => !payload.has(item[uniqKey])),
      };
    case WIDGET_SET_ANY_DATA:
      return isObject(payload) ? { ...state, ...payload } : state;
    default:
      return state;
  }
};

export interface IExtraWidgets {
  actionTypes: Set<string>;
  reducer: Reducer;
}

export const getWidgetReducer =
  (extraWidgets: IExtraWidgets[]): Reducer<IRootWidgetsState, any> =>
  (state = {}, action) => {
    const widget = get(action, `meta.widget`);

    if (widget) {
      if (widgetActions.has(action.type)) {
        return { ...state, [widget]: widgetReducer(state[widget], action) };
      } else {
        const widgetData = find(extraWidgets, ({ actionTypes }) => actionTypes.has(action.type));
        if (widgetData) {
          return { ...state, [widget]: widgetData.reducer(state[widget], action) };
        }
      }
    }

    return state;
  };
