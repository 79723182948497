import React, { useEffect, useState } from 'react';
import map from 'lodash/map';
import filter from 'lodash/filter';
import includes from 'lodash/includes';
import get from 'lodash/get';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { useTranslation } from 'react-i18next';

import useDropdownTranslator from 'hooks/form/useDropdownTranslator';
import { ISelectData } from 'types/form';

export interface IMaterialUIMultiSelectProps {
  name: string;
  onChange: (value: (string | number)[] | ISelectData[]) => void;

  value?: string[] | number[];
  data?: ISelectData[];
  translateKeys?: boolean;
  label?: string | number;
  placeholder?: string;
  error?: boolean;
}

const MaterialUIMultiSelect = ({
  data,
  translateKeys,
  value,
  label,
  placeholder,
  onChange,
  name,
  error,
}: IMaterialUIMultiSelectProps) => {
  const [t] = useTranslation();

  const [values, setValues] = useState<ISelectData[]>([]);

  const list = useDropdownTranslator(data, translateKeys);

  useEffect(() => {
    setValues(filter(list, (item) => includes(value, item.value)));
  }, [list, value]);

  return (
    <Autocomplete
      multiple
      id={name}
      options={list}
      value={values}
      onChange={(e, currentValue) => {
        onChange(map(currentValue, ({ value }) => value));
      }}
      noOptionsText={t(`common.listIsEmpty`)}
      getOptionLabel={(data) => get(data, `text`, ``)}
      renderInput={(params) => <TextField {...params} error={error} label={label} placeholder={placeholder} />}
    />
  );
};

MaterialUIMultiSelect.defaultProps = {
  data: [],
  translateKeys: false,
  error: false,
};

export default MaterialUIMultiSelect;
