import React from 'react';
import map from 'lodash/map';
import toString from 'lodash/toString';
import { useTranslation } from 'react-i18next';

import { ISelectData } from 'types/form';

export interface IRadioButtonsProps {
  onChange: (val: string | number) => void;
  name: string;

  selected?: string | number;
  data?: ISelectData[];
}

const RadioButtons = ({ data, selected, onChange, name }: IRadioButtonsProps) => {
  const [t] = useTranslation();

  return (
    <>
      {map(data, ({ value, text }) => {
        const id = `${name}_${value}_input`;

        return (
          <div className="custom-control custom-radio mb-5" key={value} id={`${name}_${value}_radio_wrap`}>
            <input
              type="radio"
              className="custom-control-input"
              value={value}
              name={name}
              id={id}
              checked={toString(selected) === toString(value)}
              onChange={(e) => onChange(e.target.value)}
            />
            <label className="custom-control-label" id={`${name}_${value}_input_label`} htmlFor={id}>
              {t(text)}
            </label>
          </div>
        );
      })}
    </>
  );
};

export default RadioButtons;
