import { useState } from 'react';

const checkTouchDevice = () => `ontouchstart` in window || !!navigator.maxTouchPoints;

const useIsTouchDevice = (): boolean => {
  const [isTouchDevice] = useState(checkTouchDevice());

  return isTouchDevice;
};

export default useIsTouchDevice;
