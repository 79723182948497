import { AxiosError } from 'axios';
import isObject from 'lodash/isObject';
import get from 'lodash/get';
import each from 'lodash/each';
import endsWith from 'lodash/endsWith';
import startsWith from 'lodash/startsWith';
import size from 'lodash/size';
import replace from 'lodash/replace';
import trim from 'lodash/trim';
import toNumber from 'lodash/toNumber';
import find from 'lodash/find';
import queryString, { ParsedUrlQueryInput } from 'querystring';

import {
  LATIN_DIGITS_AND_SYMBOLS_LOWER_REGEX,
  LATIN_DIGITS_AND_SYMBOLS_REGEX,
  LATIN_DIGITS_AND_SYMBOLS_WITH_SPACE_REGEX,
  STRICT_LATIN_DIGITS_AND_SYMBOLS_LOWER_REGEX,
} from 'utils/constants';
import { IError } from '../types/common';

export const normalizeErrorMessage = (err: Record<string, unknown> | string, onlyValue = false): string => {
  if (isObject(err)) {
    let message = ``;

    each(err, (value, key) => {
      if (onlyValue) {
        message += value;
      } else {
        message += `${key}: ${value}`;
      }
      message += `\n`;
    });

    return message;
  }

  return err;
};

export const getErrorMessage = (
  err?: Error | AxiosError,
  {
    normalize = false,
    defaultValue = ``,
    onlyValue = false,
  }: {
    normalize?: boolean;
    defaultValue?: string;
    onlyValue?: boolean;
  } = {}
): string | Record<string, unknown> => {
  const message = get(err, `response.data.message`);

  if (!message) {
    return defaultValue || get(err, `message`, ``);
  }

  return normalize ? normalizeErrorMessage(message, onlyValue) : message;
};

export const formatErrors = (errors: IError[]): Record<string, unknown> => {
  const obj: Record<string, unknown> = {};
  errors.forEach((err) => {
    const { target, message } = err;
    obj[target] = message;
  });

  return obj;
};

export const getNormalizedUrl = (url: string): string => (endsWith(url, `/`) ? url : `${url}/`);

export const addPoint = (value: string): string => {
  value = value.replace(/,/g, `.`);

  if (startsWith(value, `0`) && size(value) > 1 && !startsWith(value, `.`, 1)) {
    value = `0.${value.slice(1)}`;
  }

  return value;
};

export const amountField = (value: string): string => {
  value = addPoint(value);
  // eslint-disable-next-line
  value = value.replace(/[^\d\.]/g, ``);

  return value;
};

export const geoField = (value: string): string => {
  value = value.replace(/[^-\d\.]/g, ``);
  let precision = value.split(`.`)[1];
  value = (+value).toString();

  precision = (precision || `0`).slice(0, 15);
  const decimal = value.split(`.`)[0];

  return `${decimal}.${precision}`;
};

export const standardAmountField = (value: string): string => {
  value = addPoint(value);

  if (!/^\d{1,20}(\.{1}\d{0,2})?$/.test(value)) {
    value = value.slice(0, -1);
  }

  return value;
};

export const percentField = (value: string): string => {
  value = addPoint(value);

  if (!/^\d{1,20}(\.{1}\d{0,2})?$/.test(value)) {
    value = value.slice(0, -1);
  }
  const regExp = new RegExp(`^\\d{1,20}(\\.{1}\\d{0,2})?$`);
  const outOfRange = toNumber(value) > 100;
  const isCorrect = regExp.test(value) && !outOfRange;

  return isCorrect ? value : value.slice(0, -1);
};

export const isValidHttpsURL = (str: string): boolean => {
  const pattern = new RegExp(
    `^(https:\\/\\/)` + // protocol
      `((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|` + // domain name
      `((\\d{1,3}\\.){3}\\d{1,3}))` + // OR ip (v4) address
      `(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*` + // port and path
      `(\\?[;&a-z\\d%_.~+=-]*)?` + // query string
      `(\\#[-a-z\\d_]*)?$`,
    `i`
  ); // fragment locator

  return pattern.test(str);
};

export const latinDigitsAndSymbolsInput = (value: string): string => {
  return replace(value, LATIN_DIGITS_AND_SYMBOLS_REGEX, ``);
};

export const latinDigitsAndSymbolsLowerInput = (value: string): string => {
  return replace(value, LATIN_DIGITS_AND_SYMBOLS_LOWER_REGEX, ``);
};

export const latinDigitsAndSymbolsSpaceInput = (value: string): string => {
  return replace(value, LATIN_DIGITS_AND_SYMBOLS_WITH_SPACE_REGEX, ``);
};

export const latinDigitsAndSymbolsStrictInput = (value: string): string => {
  return replace(value, STRICT_LATIN_DIGITS_AND_SYMBOLS_LOWER_REGEX, ``);
};

export const numInput = (value: string): string => {
  value = value.replace(/[^\d]/g, ``);

  if (startsWith(value, `0`)) {
    value = value.slice(0, 1);
  }

  return value;
};

export const trimmedInput = (value: string): string => trim(value);

export const sleep = (ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export const pressEsc = (e: KeyboardEvent): boolean => e.code === `Escape`;

export const getCoordsOfElem = (elem: HTMLElement | null) => {
  if (!elem) return {};

  const { top, left, bottom, right } = elem.getBoundingClientRect();

  return { top: Math.round(top), left: Math.round(left), bottom: Math.round(bottom), right: Math.round(right) };
};

export const getHostName = (dataUrl: string): string => {
  const url = new URL(dataUrl);

  return url.hostname;
};

export const findDeep = <R>(
  // eslint-disable-next-line @typescript-eslint/ban-types
  entireObj: object,
  keyToFind: string,
  valToFind: string | number
): R | null => {
  let foundObj = null;
  JSON.stringify(entireObj, (_, nestedValue) => {
    if (nestedValue && nestedValue[keyToFind] === valToFind) {
      foundObj = nestedValue;
    }

    return nestedValue;
  });

  return foundObj;
};

export const range = (start: number, end: number): number[] => {
  if (start > end) {
    return [];
  }

  const arr = [];
  for (let i = start; i <= end; i++) {
    arr.push(i);
  }

  return arr;
};

export const digitInput = (value: string): string => value.replace(/[^\d]/g, ``);

export const getNavActualPath = (paths: { path: string; access: boolean }[]): string => {
  return get(
    find(paths, ({ access }) => access),
    `path`
  ) as string;
};

export const updateSearchParams = (location: Location, search?: ParsedUrlQueryInput): string => {
  return `${location?.pathname}?${queryString.stringify(search)}`;
};

export interface IImitateLinkClickParams {
  fileName?: string;
  download?: boolean;
  openInNewTab?: boolean;
}

export const imitateLinkClick = (
  url: string,
  { fileName, download = false, openInNewTab = false }: IImitateLinkClickParams = {}
) => {
  const a = document.createElement(`a`);
  a.href = url;
  if (download && fileName) {
    a.download = fileName;
  }
  if (openInNewTab) {
    a.target = `_blank`;
  }
  document.body.appendChild(a);
  a.click();
  setTimeout(() => {
    document.body.removeChild(a);
  }, 0);
};

export interface IDownloadFileParams {
  data: File;
  fileName: string;
  type?: string | null;
}

export const downloadFile = function download({ data, fileName, type }: IDownloadFileParams) {
  const file = new Blob([data], { type: type as string });

  if ((window.navigator as any).msSaveOrOpenBlob) {
    // IE10+
    (window.navigator as any).msSaveOrOpenBlob(file, fileName);
  } else {
    const a = document.createElement(`a`);
    const url = URL.createObjectURL(file);
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    setTimeout(() => {
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    }, 0);
  }
};
