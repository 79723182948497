import React, { useCallback, useState } from 'react';
import IconButton from '@material-ui/core/IconButton';
import Input, { InputProps } from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { useTranslation } from 'react-i18next';

import capsLockWarning from 'assets/media/icons/caps-lock-warning.svg';

export interface IPasswordProps extends InputProps {
  name: string;
  value: string;
  className?: string;
}

const Password = ({ className, name, value, onChange, ...props }: IPasswordProps) => {
  const [t] = useTranslation();

  const [isCapsLocked, setIsCapsLocked] = useState<boolean>(false);
  const [show, setShow] = useState<boolean>(false);

  const capsDetectionCallback = useCallback((e) => {
    setIsCapsLocked(e.getModifierState(`CapsLock`));
  }, []);

  return (
    <Input
      {...props}
      id={name}
      className={className}
      type={show ? `text` : `password`}
      value={value}
      onChange={onChange}
      onKeyUp={capsDetectionCallback}
      onMouseDown={capsDetectionCallback}
      endAdornment={
        <InputAdornment position="end">
          {isCapsLocked && (
            <div title={t(`common.capsLockIsOn`)} className="input__icon input__icon--caps-lock">
              <img src={capsLockWarning} alt="" title={t(`common.capsLockIsOn`)} width="16" />
            </div>
          )}
          <IconButton aria-label="toggle password visibility" onClick={() => setShow(!show)}>
            {show ? <Visibility /> : <VisibilityOff />}
          </IconButton>
        </InputAdornment>
      }
    />
  );
};

export default Password;
