import { Reducer } from 'react';
import { INIT_APP_SUCCEEDED } from 'actionTypes';
import { IConfigState } from 'types/config';
import { IAction } from 'types/common';

const config: Reducer<IConfigState, IAction> = (state = {}, { type, payload = {} }) => {
  switch (type) {
    case INIT_APP_SUCCEEDED:
      return { ...state, ...payload };
    default:
      return state;
  }
};

export default config;
