import React, { useCallback, useEffect, useState } from 'react';
import {
  Editor,
  EditorState,
  convertToRaw,
  RichUtils,
  ContentState,
  convertFromHTML,
  DraftHandleValue,
  DraftEditorCommand,
} from 'draft-js';
import get from 'lodash/get';
import size from 'lodash/size';
import isString from 'lodash/isString';
import isObject from 'lodash/isObject';
import reduce from 'lodash/reduce';
import { ITextEditorValue } from 'types/form';
import { stateToHTML } from 'draft-js-export-html';

export interface ITextEditorProps {
  onChange: (value: ITextEditorValue) => void;
  defaultValue: string | ITextEditorValue;
  id: string | number;
}

function replace(html: string) {
  return html.replace(/<(?!\/?\w+>)/g, `&lt;`);
}

const TextEditor = ({ onChange, defaultValue, id }: ITextEditorProps) => {
  if (isObject(defaultValue)) {
    defaultValue = get(defaultValue, `html`);
  }

  const [defaultValueState, setDefaultValueState] = useState(defaultValue);
  const [editorState, setEditorState] = useState<EditorState>(() => {
    if (isString(defaultValue)) {
      // const converted = convertFromHTML(defaultValue);
      const converted = convertFromHTML(replace(defaultValue));

      return EditorState.createWithContent(
        ContentState.createFromBlockArray(converted.contentBlocks, converted.entityMap)
      );
    }

    return EditorState.createEmpty();
  });

  useEffect(() => {
    if (!defaultValueState && isString(defaultValue)) {
      setDefaultValueState(replace(defaultValue));
      const converted = convertFromHTML(defaultValue);
      setEditorState(
        EditorState.createWithContent(ContentState.createFromBlockArray(converted.contentBlocks, converted.entityMap))
      );
    }
  }, [defaultValue]);

  const editor = React.useRef(null);

  useEffect(() => {
    const rawData = convertToRaw(editorState.getCurrentContent());
    const value = reduce(
      rawData.blocks,
      (result, { text }, index) => {
        if (text !== null) {
          if (index === size(rawData.blocks) - 1) {
            return result + text;
          } else {
            return `${result}${text}\n`;
          }
        }

        return result;
      },
      ``
    );
    const html = stateToHTML(editorState.getCurrentContent(), {
      inlineStyles: {
        BOLD: { element: `b` },
      },
    });
    onChange({ value, html });
  }, [editorState]);

  const changeHandler = useCallback((editorState: EditorState) => setEditorState(editorState), []);

  const handleKeyCommand = useCallback(
    (command: DraftEditorCommand, editorState: EditorState): DraftHandleValue => {
      const newState = RichUtils.handleKeyCommand(editorState, command);
      if (newState) {
        changeHandler(newState);

        return `handled`;
      }

      return `not-handled`;
    },
    [changeHandler]
  );

  const focusEditor = useCallback(() => {
    if (editor && editor.current) {
      (editor.current as any).focus();
    }
  }, [editor]);

  return (
    <div onClick={focusEditor} className="text-editor-wrapper form-control" id={`${id}_text_editor`}>
      <Editor ref={editor} editorState={editorState} handleKeyCommand={handleKeyCommand} onChange={changeHandler} />
    </div>
  );
};

export default TextEditor;
