import React from 'react';
import { useTranslation } from 'react-i18next';
import get from 'lodash/get';
import { Dispatch } from 'redux';

import { IWidgetActionCreators, IWidgetModal } from 'types/widgets';

import ModalElement from 'components/modals/ModalElement';

export interface IInfoModalProps {
  actionCreators: IWidgetActionCreators;
  title: string;
  dispatch: Dispatch;
  modal: IWidgetModal | null;
}

const InfoModal = ({ actionCreators, modal, dispatch, ...props }: IInfoModalProps) => {
  const [t] = useTranslation();

  const content = get(modal, `content`);

  const footer = (
    <button className="btn btn-primary" onClick={() => dispatch(actionCreators.hideModal())}>
      {t(`common.gotIt`)}
    </button>
  );

  return (
    <ModalElement
      {...props}
      dispatch={dispatch}
      footer={footer}
      content={t(content)}
      actionCreators={actionCreators}
      footerClassName="modal__footer--confirmation"
    />
  );
};

export default InfoModal;
