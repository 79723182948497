import React, { ChangeEvent, ReactElement, useCallback, useRef, useState } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import Cleave from 'cleave.js/react';
import { WrappedFieldProps } from 'redux-form';
import map from 'lodash/map';

import useIsTouchDevice from 'hooks/useIsTouchDevice';
import { IInputProps, ISelectData } from 'types/form';
import { InputType } from 'utils/enums';

import DropdownSelect from 'components/form/inputs/dropdownSelect/DropdownSelect';
import Checkbox from 'components/form/inputs/checkbox/Checkbox';
import MaterialUICheckbox from 'components/form/inputs/checkbox/MaterialUICheckbox';
import RadioButtons from 'components/form/inputs/RadioButtons';
import MaterialUIDropdownSelect from 'components/form/inputs/dropdownSelect/MaterialUIDropdownSelect';
import MultiSelect from 'components/form/inputs/multiSelect/MultiSelect';
import MaterialUIMultiSelect from 'components/form/inputs/multiSelect/MaterialUIMultiSelect';
import TextEditor from 'components/form/inputs/TextEditor';
import TextInput from 'components/form/inputs/TextInput';
import RangeDatepicker from 'components/form/inputs/datepicker/RangeDatePicker';
import Datepicker from 'components/form/inputs/datepicker/Datepicker';
import Dropzone from 'components/form/inputs/file/Dropzone';
import Password from 'components/form/inputs/Password';
import RangeTime from 'components/form/inputs/RangeTime';
import FileInput from 'components/form/inputs/file/File';
import SimpleFile from 'components/form/inputs/file/SimpleFile';
import PhoneInput from 'components/form/inputs/PhoneInput';
import MaterialUIRangeDataPicker from 'components/form/inputs/datepicker/MaterialUIRangeDataPicker';
import MaterialUIDatePicker from 'components/form/inputs/datepicker/MaterialUIDatePicker';
import capsLockWarning from 'assets/media/icons/caps-lock-warning.svg';
import DateTimepicker from '../inputs/datepicker/DateTimepicker';
import Title from '../inputs/Title';

export interface IFormInputProps extends WrappedFieldProps, IInputProps {
  handleChange?: (input: any) => (value: any) => void;
  clearValueWithNoDate?: boolean;
}

const FormInput = ({
  titleText,
  id,
  input,
  type,
  label,
  meta: { error, touched, submitFailed },
  data,
  placeholder,
  half,
  marginRight,
  autocompletePassword,
  hidden,
  className,
  containerClassName,
  isModalField,
  isPaginatorField,
  fromValue,
  toValue,
  checkboxAsNumValue,
  forcedShowingError,
  formGroup,
  fileAcceptedTypes,
  deleteFileHandler,
  dropFileHandler,
  maxSize,
  showDeleteButton,
  multiple,
  timePicker,
  hideOnDateChanging,
  errorCallback,
  description,
  arrayField,
  wrapClassName,
  checkboxLabelClassName,
  minDate,
  maxDate,
  withModalScroll,
  defaultFileName,
  disableDropdown,
  countryCodeEditable,
  countryButtonHidden,
  initialValue,
  nameValidation,
  nameMask,
  showSeconds,
  withFormGroupClass = true,
  timezone,
  ...rest
}: IFormInputProps): ReactElement | null => {
  const [showPassword, setShowPassword] = useState(false);
  const [isCapsLocked, setIsCapsLocked] = useState(false);

  const [t] = useTranslation();

  const isTouchDevice = useIsTouchDevice();

  const fieldWrapperRef = useRef(null);

  const capsDetectionCallback = useCallback((e) => {
    setIsCapsLocked(e.getModifierState(`CapsLock`));
  }, []);

  if (hidden) {
    return null;
  }

  const showError = !!(
    (forcedShowingError && error) ||
    (submitFailed && touched && error) ||
    (arrayField && error && submitFailed)
  );

  if (type === InputType.CHECKBOX || type === InputType.MUI_CHECKBOX) {
    const onCheckboxChange = (e: ChangeEvent<HTMLInputElement>) => {
      if (checkboxAsNumValue) {
        input.onChange(e.target.checked ? 1 : 0);
      } else {
        input.onChange(e);
      }
    };

    if (type === InputType.CHECKBOX) {
      return (
        <Checkbox
          disabled={rest.disabled}
          name={input.name}
          label={label}
          onChange={onCheckboxChange}
          isModalField={isModalField}
          checkboxLabelClassName={checkboxLabelClassName}
          checked={input.checked}
          description={description}
        />
      );
    }

    return (
      <MaterialUICheckbox
        onChange={onCheckboxChange}
        label={label}
        checked={input.checked}
        labelClassName={checkboxLabelClassName}
        description={description}
      />
    );
  }

  if (type === InputType.SIMPLE_FILE) {
    return (
      <SimpleFile
        value={input.value}
        onChange={input.onChange}
        name={input.name}
        defaultFileName={defaultFileName}
        fileAcceptedTypes={fileAcceptedTypes}
      />
    );
  }

  if (type === InputType.RADIO) {
    return <RadioButtons onChange={input.onChange} data={data} name={input.name} selected={input.value} />;
  }

  let field;
  switch (type) {
    case InputType.SELECT:
      field = (
        <DropdownSelect
          data={data}
          {...rest}
          {...input}
          onChange={(item: ISelectData) => {
            if (rest.handleChange) {
              rest.handleChange(input)(item);
            } else {
              input.onChange(item.value);
            }
          }}
          placeholder={placeholder}
          className={className}
          fieldWrapperRef={fieldWrapperRef}
          isModalField={withModalScroll || isModalField}
          id={id || input.name}
        />
      );
      break;
    case InputType.MUI_SELECT:
      field = (
        <MaterialUIDropdownSelect
          data={data}
          {...rest}
          {...input}
          id={input.name}
          placeholder={placeholder}
          error={showError}
        />
      );
      break;
    case InputType.MULTI_SELECT:
      field = (
        <MultiSelect
          data={data}
          {...rest}
          {...input}
          onChange={(dataItems) =>
            input.onChange(
              map(dataItems, (item) => {
                if (item && item.value) {
                  return item.value;
                }

                return item;
              })
            )
          }
          placeholder={placeholder}
          className={className}
          fieldWrapperRef={fieldWrapperRef}
          isModalField={withModalScroll || isModalField}
        />
      );
      break;
    case InputType.MUI_MULTI_SELECT:
      field = (
        <MaterialUIMultiSelect
          data={data}
          {...rest}
          {...input}
          onChange={(dataItems) =>
            input.onChange(
              map(dataItems, (item) => {
                if (typeof item === `object` && item.value) {
                  return item.value;
                }

                return item;
              })
            )
          }
          placeholder={placeholder}
          error={showError}
        />
      );
      break;
    case InputType.TEXT_EDITOR:
      field = <TextEditor onChange={input.onChange} defaultValue={input.value} id={input.name} />;
      break;
    case InputType.MUI_TEXT:
      field = (
        <TextInput
          placeholder={placeholder}
          {...input}
          {...rest}
          id={input.name}
          className={classNames(`new-text-editor`, className)}
          error={showError}
        />
      );
      break;
    case InputType.TEXTAREA:
      field = (
        <textarea
          rows={3}
          {...input}
          {...rest}
          id={input.name}
          placeholder={placeholder}
          className={classNames(`form-control`, className)}
        />
      );
      break;
    case InputType.PHONE_NUMBER:
      field = (
        <PhoneInput
          {...input}
          disableDropdown={disableDropdown}
          countryCodeEditable={countryCodeEditable}
          countryButtonHidden={countryButtonHidden}
          initialValue={initialValue}
        />
      );
      break;
    case InputType.DATE_RANGE:
      field = (
        <RangeDatepicker
          fromValue={fromValue}
          toValue={toValue}
          input={input}
          timePicker={timePicker}
          hideOnDateChanging={hideOnDateChanging}
          minDate={minDate}
          maxDate={maxDate}
          timezone={timezone}
          showSeconds={showSeconds}
          placeholder={placeholder}
        />
      );
      break;
    case InputType.INPUT_TITLE:
      field = <Title titleText={titleText} />;
      break;
    case InputType.DATE_TIME_PICKER:
      field = (
        <DateTimepicker
          fromValue={fromValue}
          input={input}
          timePicker={timePicker}
          hideOnDateChanging={hideOnDateChanging}
          minDate={minDate}
          maxDate={maxDate}
          timezone={timezone}
          showSeconds={showSeconds}
          placeholder={placeholder}
        />
      );
      break;
    case InputType.MUI_DATE_RANGE:
      field = (
        <MaterialUIRangeDataPicker
          fromValue={fromValue}
          toValue={toValue}
          input={input}
          timePicker={timePicker}
          hideOnDateChanging={hideOnDateChanging}
          minDate={minDate}
          maxDate={maxDate}
          showSeconds={showSeconds}
        />
      );
      break;
    case InputType.DATE:
      field = <Datepicker input={input} minDate={minDate} maxDate={maxDate} />;
      break;
    case InputType.MUI_DATE:
      field = (
        <MaterialUIDatePicker
          input={input}
          minDate={minDate}
          maxDate={maxDate}
          showTimeSelect={timePicker}
          value={rest.value}
          clearValueWithNoDate={rest.clearValueWithNoDate}
        />
      );
      break;
    case InputType.TIME_RANGE:
      field = <RangeTime input={input} showSeconds={showSeconds} />;
      break;
    case InputType.DROPZONE:
      field = (
        <Dropzone
          name={input.name}
          onChange={input.onChange}
          defaultValue={input.value}
          fileAcceptedTypes={fileAcceptedTypes}
          deleteFileHandler={deleteFileHandler}
          dropFileHandler={dropFileHandler}
          maxSize={maxSize}
          showDeleteButton={showDeleteButton}
          multiple={multiple}
          containerClassName={containerClassName}
          errorCallback={errorCallback}
          nameValidation={nameValidation}
          nameMask={nameMask}
        />
      );
      break;
    case InputType.FILE:
      field = (
        <FileInput
          name={input.name}
          onChange={input.onChange}
          value={input.value}
          defaultFileName={defaultFileName}
          maxSize={maxSize}
          fileAcceptedTypes={fileAcceptedTypes}
        />
      );
      break;
    case InputType.CARD_NUMBER:
      field = (
        <Cleave
          {...input}
          {...rest}
          placeholder={placeholder}
          className={classNames(`form-control`, className)}
          options={{
            creditCard: true,
            creditCardStrictMode: true,
          }}
        />
      );
      break;
    case InputType.MAC_ADDRESS:
      field = (
        <Cleave
          {...input}
          {...rest}
          placeholder={placeholder}
          className={classNames(`form-control`, className)}
          options={{
            blocks: [2, 2, 2, 2, 2, 2],
            delimiter: `:`,
          }}
        />
      );
      break;
    case InputType.CARD_DATE:
      field = (
        <Cleave
          {...input}
          {...rest}
          placeholder={placeholder}
          className={classNames(`form-control`, className)}
          options={{
            date: true,
            datePattern: [`m`, `y`],
          }}
        />
      );
      break;
    case InputType.PASSWORD:
      field = (
        <div className="position-relative">
          <input
            {...input}
            type={!showPassword ? `password` : `text`}
            autoComplete={autocompletePassword ? `on` : `new-password`}
            className="form-control input__field--password"
            placeholder={placeholder}
            onKeyUp={capsDetectionCallback}
            onMouseDown={capsDetectionCallback}
            {...rest}
          />
          <div className="input__icons-wrap">
            {isCapsLocked && (
              <div title={t(`common.capsLockIsOn`)} className="input__icon input__icon--caps-lock">
                <img src={capsLockWarning} alt="" title={t(`common.capsLockIsOn`)} width="16" />
              </div>
            )}
            <button
              className="input__icon"
              onClick={() => setShowPassword(!showPassword)}
              type="button"
              title={t(showPassword ? `widgets.hideThePassword` : `widgets.showThePassword`)}
            >
              <i className="si si-eye" />
            </button>
          </div>
        </div>
      );
      break;
    case InputType.MUI_PASSWORD:
      field = <Password {...input} {...rest} className={classNames(`new-password`, className)} error={showError} />;
      break;
    default:
      field = (
        <input
          {...input}
          type={type}
          id={input.name}
          autoComplete="off"
          placeholder={placeholder}
          className={classNames(`form-control`, className)}
          {...rest}
        />
      );
  }

  if (isModalField) {
    const fieldArea = (
      <>
        {field}
        {showError && <span className="text-error text-wrap-break-word">{error}</span>}
        {description && isTouchDevice && <p className="text-muted font-size-sm">{t(description)}</p>}
      </>
    );

    let content;
    if (label) {
      content = (
        <>
          <label className="col-lg-4 col-form-label">
            {label}
            {description && !isTouchDevice && <i className="fa fa-question-circle-o ml-5" title={t(description)} />}
          </label>
          <div className="col-lg-8">{fieldArea}</div>
        </>
      );
    } else {
      content = <div className="col-lg-12">{fieldArea}</div>;
    }

    return (
      <div ref={fieldWrapperRef} className={classNames(`form-group`, `row`, { 'is-invalid': showError })}>
        {content}
      </div>
    );
  }

  if (isPaginatorField) {
    return (
      <div ref={fieldWrapperRef} className={classNames(`form-group`, `row`, { 'is-invalid': showError })}>
        <label className="col-8 col-form-label">
          {label}
          {description && !isTouchDevice && <i className="fa fa-question-circle-o ml-5" title={t(description)} />}
        </label>
        <div className="col-4">
          {field}
          {showError && <span className="text-error text-wrap-break-word">{error}</span>}
          {description && isTouchDevice && <p className="text-muted font-size-sm">{t(description)}</p>}
        </div>
      </div>
    );
  }

  if (!formGroup) {
    return (
      <div ref={fieldWrapperRef} className={classNames(wrapClassName, { 'is-invalid': showError })}>
        {field}
        {showError && <span className="text-error">{error}</span>}
      </div>
    );
  }

  return (
    <div
      ref={fieldWrapperRef}
      className={classNames(``, {
        'form-group': withFormGroupClass,
        'is-invalid': showError,
        'w-50': half,
        'margin-right': marginRight,
      })}
    >
      {label && (
        <label htmlFor={input.name}>
          {label}
          {description &&
            (!isTouchDevice ? (
              <i className="fa fa-question-circle-o ml-5" title={t(description)} />
            ) : (
              <p className="text-muted font-size-sm">{t(description)}</p>
            ))}
        </label>
      )}
      {field}
      {showError && <span className="text-error display-block">{error}</span>}
    </div>
  );
};

FormInput.defaultProps = {
  type: InputType.TEXT,
  label: null,
  data: null,
  placeholder: ``,
  half: false,
  marginRight: false,
  autocompletePassword: false,
  hidden: false,
  isModalField: false,
  checkboxAsNumValue: false,
  forcedShowingError: false,
  formGroup: true,
};

export default FormInput;
