import { createSelector } from 'reselect';

import { IRootState } from 'types/common';
import { IConfigState } from 'types/config';

export const configSelector = (state: IRootState): IConfigState => state.config;

export const apiUrlSelector = createSelector<IRootState, IConfigState, string | undefined>(
  configSelector,
  ({ apiUrl }) => apiUrl
);

export const sameDomainNameForApiSelector = createSelector<IRootState, IConfigState, boolean | undefined>(
  configSelector,
  ({ useTheSameDomainNameForApi }) => useTheSameDomainNameForApi
);

export const apiSubdomainSelector = createSelector<IRootState, IConfigState, string | undefined>(
  configSelector,
  ({ apiSubdomain }) => apiSubdomain
);

export const apiProtocolSelector = createSelector<IRootState, IConfigState, string | undefined>(
  configSelector,
  ({ apiProtocol }) => apiProtocol
);

export const pluginUrlSelector = createSelector<IRootState, IConfigState, string | undefined>(
  configSelector,
  ({ pluginUrl }) => pluginUrl
);
