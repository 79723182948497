import { useEffect, useState } from 'react';
import get from 'lodash/get';
import find from 'lodash/find';
import isNumber from 'lodash/isNumber';

import { addPoint } from 'utils';
import { CRYPTOCURRENCY_PRECISION } from 'utils/constants';
import { ICurrency } from 'types/currencies';

const STANDARD_PRECISION = 2;

interface IIsCryptParams {
  currencyCode?: string;
  currencies?: ICurrency[];
}

export const useIsCrypt = ({ currencyCode, currencies }: IIsCryptParams): boolean => {
  const [isCrypt, setIsCrypt] = useState<boolean>(false);

  useEffect(() => {
    const currency = find(currencies, { code: currencyCode });

    if (currency) {
      setIsCrypt(get(currency, `is_crypt`));
    }
  }, [currencies, currencyCode]);

  return isCrypt;
};

export interface ICurrencyFieldParams {
  currency?: string;
  currencies?: ICurrency[];
  precision?: number;
}

export interface ICurrencyFieldResult {
  normalizer: (value: string) => string;
  isCrypt: boolean;
}

const useCurrencyField = ({
  currency: currencyCode,
  precision: particularPrecision,
  currencies,
}: ICurrencyFieldParams): ICurrencyFieldResult => {
  const isCrypt = useIsCrypt({ currencyCode, currencies });

  return {
    normalizer: (value: string) => {
      value = addPoint(value);

      let precision;
      if (isNumber(particularPrecision)) {
        precision = particularPrecision;
      } else {
        precision = isCrypt ? CRYPTOCURRENCY_PRECISION : STANDARD_PRECISION;
      }

      const regExp = new RegExp(`^\\d{1,20}(\\.{1}\\d{0,${precision}})?$`);

      return regExp.test(value) ? value : value.slice(0, -1);
    },
    isCrypt,
  };
};

export default useCurrencyField;
