import get from 'lodash/get';
import find from 'lodash/find';
import trimEnd from 'lodash/trimEnd';
import isNumber from 'lodash/isNumber';
import toString from 'lodash/toString';
import currency from 'currency.js';

import { ICurrency } from 'types/currencies';

const CRYPTOCURRENCY_PRECISION = 8;

export interface IFullAmountParams {
  amount: string | number;
  currencySymbol?: string;
  currencyCode?: string;
  showSymbol?: boolean;
  precision?: number;
  convertToNumber?: boolean;
  needToTrim?: boolean;
  currencies: ICurrency[];
}

interface INormalizedAmountParams {
  amount: number | string;
  isCrypt?: boolean;
  precision?: number;
  [key: string]: any;
  convertToNumber?: boolean;
  needToTrim?: boolean;
}

const trimAmount = (amount: string | number) => {
  const amountWithTrimmedZeros = trimEnd(toString(amount), `0`);

  return trimEnd(amountWithTrimmedZeros, `.`);
};

const getNormalizedAmount = ({
  amount,
  isCrypt,
  precision: particularPrecision,
  convertToNumber,
  needToTrim,
  ...params
}: INormalizedAmountParams): string | number => {
  params = {
    symbol: ``,
    pattern: `# !`,
    negativePattern: `-# !`,
    ...params,
  };

  const needToTrimAmount = isNumber(particularPrecision) || isCrypt;

  if (needToTrimAmount) {
    const precision = isCrypt ? CRYPTOCURRENCY_PRECISION : particularPrecision;

    if (convertToNumber) {
      return currency(amount, { ...params, precision }).value;
    }

    const formattedFullAmount = currency(amount, { ...params, precision }).format();
    const [formattedAmount, symbol] = formattedFullAmount.split(` `);

    if (needToTrim) {
      return [trimAmount(formattedAmount), symbol].join(` `);
    } else {
      return [formattedAmount, symbol].join(` `);
    }
  }

  return convertToNumber ? currency(amount, params).value : currency(amount, params).format();
};

const useFullAmount = (currencies: ICurrency[] | null) => {
  return ({
    amount,
    precision,
    currencySymbol,
    currencyCode,
    convertToNumber = false,
    showSymbol = true,
    needToTrim = true,
  }: IFullAmountParams) => {
    let isCrypt = false;
    let code = ``;

    const currency = find(currencies, (currency) => {
      if (currencySymbol) return get(currency, `symbol`) === currencySymbol;
      else if (currencyCode) return get(currency, `code`) === currencyCode;

      return false;
    });

    if (currency) {
      isCrypt = get(currency, `is_crypt`);

      if (showSymbol) {
        if (currencyCode) {
          code = currencyCode;
        } else if (currencySymbol) {
          code = get(currency, `code`);
        }
      }
    }

    return getNormalizedAmount({
      amount,
      isCrypt,
      symbol: code,
      precision,
      convertToNumber,
      needToTrim,
    });
  };
};

export default useFullAmount;
