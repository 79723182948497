import { MutableRefObject, useCallback, useRef, useState } from 'react';

const DROPDOWN_HEIGHT = 150;
const INPUT_HEIGHT = 50;

interface IOpenToUpParams {
  fieldOffset: number;
  height: number;
  scrollY: number;
  formOffset: number;
}

const openToUp = ({ fieldOffset, height, scrollY, formOffset }: IOpenToUpParams): boolean => {
  const offset = fieldOffset + scrollY - formOffset;
  const dropdownHeight = DROPDOWN_HEIGHT + INPUT_HEIGHT;

  return offset + dropdownHeight > height + scrollY;
};

export interface IDropdownOpeningToUpParams {
  fieldWrapperRef?: MutableRefObject<any>;
  isModalField?: boolean;
}

export interface IDropdownOpeningToUpResult {
  doMeasure: () => void;
  selectRef: MutableRefObject<any>;
  openUp: boolean;
}

const useDropdownOpeningToUp = ({
  fieldWrapperRef,
  isModalField,
}: IDropdownOpeningToUpParams): IDropdownOpeningToUpResult => {
  const [openUp, setOpenUp] = useState<boolean>(false);

  const selectRef = useRef(null);

  const doMeasure = useCallback(() => {
    let field;
    if (fieldWrapperRef && fieldWrapperRef.current) {
      field = fieldWrapperRef.current;
    } else if (selectRef && selectRef.current) {
      field = selectRef.current;
    }

    if (field) {
      const fieldOffset = field.getBoundingClientRect().top;

      if (isModalField) {
        const modalContent = document.querySelector(`#modal-content`);
        if (modalContent && modalContent.classList.contains(`modal__content-wrap--overflowed`)) {
          setOpenUp(
            openToUp({
              fieldOffset,
              height: modalContent.clientHeight,
              scrollY: modalContent.scrollTop,
              formOffset: modalContent.getBoundingClientRect().top,
            })
          );
        }
      } else {
        setOpenUp(
          openToUp({
            fieldOffset,
            height: window.innerHeight,
            scrollY: window.scrollY,
            formOffset: 0,
          })
        );
      }
    }
  }, [fieldWrapperRef, selectRef]);

  return {
    doMeasure,
    selectRef,
    openUp,
  };
};

export default useDropdownOpeningToUp;
