import React, { ReactNode, RefObject, useCallback, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import useSize from 'react-use/lib/useSize';
import useKeyPressEvent from 'react-use/lib/useKeyPressEvent';

import { Size } from 'utils/enums';
import { pressEsc } from 'utils';
import { IModalProps } from 'types/common';

import Preloader from 'components/base/Preloader';

const MIN_HEIGHT_FOR_OVERFLOWING_CONTENT = 400;

interface IModalContentProps {
  children: ReactNode;

  contentRef?: RefObject<any>;
}

const ModalContent = ({ children }: IModalContentProps) => {
  const [withOverflow, setWithOverflow] = useState(false);

  const ref = useRef(null);

  const render = () => (
    <div
      className={classNames(`modal__content-wrap`, { 'modal__content-wrap--overflowed': withOverflow })}
      ref={ref}
      id="modal-content"
    >
      {children}
    </div>
  );

  const [elem, { height }] = useSize(render);

  useEffect(() => {
    setWithOverflow(height > MIN_HEIGHT_FOR_OVERFLOWING_CONTENT);
  }, [height]);

  return elem;
};

export type IModalElementProps = Omit<IModalProps, `modal`>;

const ModalElement = ({
  title,
  actionCreators: { hideModal },
  loading,
  content,
  footer,
  size,
  footerClassName,
  className,
  contentRef,
  dispatch,
}: IModalElementProps) => {
  const modalRef = useRef(null);

  const [t] = useTranslation();

  const closeHandler = useCallback(() => dispatch(hideModal()), [hideModal, dispatch]);

  useKeyPressEvent(pressEsc, closeHandler);

  const _className = classNames(`modal-dialog`, className, {
    'modal--small': size === Size.LITTLE,
    'modal--middle': size === Size.MIDDLE,
    'modal--big': size === Size.BIG,
  });

  if (loading) {
    content = (
      <div className="p-10">
        <Preloader />
      </div>
    );
  } else if (content) {
    content = <ModalContent contentRef={contentRef}>{content}</ModalContent>;
  }

  return (
    <>
      <div className="modal show">
        <div className={_className} ref={modalRef}>
          <div className="modal-content">
            <div className="block block-themed block-transparent mb-0">
              <div className="block-header bg-primary-dark">
                <h3 className="block-title">{t(title)}</h3>
                <div className="block-options">
                  <button type="button" id="close_modal_btn" className="btn-block-option" onClick={closeHandler}>
                    <i className="si si-close" />
                  </button>
                </div>
              </div>
              {content}
              {footer && <div className={classNames(`modal__footer`, footerClassName)}>{footer}</div>}
            </div>
          </div>
        </div>
      </div>
      <div className="modal-backdrop show" />
    </>
  );
};

ModalElement.defaultProps = {
  size: Size.LITTLE,
  loading: false,
};

export default ModalElement;
