import React, { useCallback, useEffect, useState } from 'react';
import isString from 'lodash/isString';
import size from 'lodash/size';
import find from 'lodash/find';
import endsWith from 'lodash/endsWith';
import join from 'lodash/join';
import { useDropzone } from 'react-dropzone';

import { downloadFile } from 'utils';

import fileDownloadIcon from 'assets/media/icons/file-download-btn.svg';
import { useTranslation } from 'react-i18next';

export interface ISimpleFileProps {
  value: string | File | null;
  onChange: (e: File) => void;
  name: string;
  defaultFileName?: string;
  fileAcceptedTypes?: string[];
}

const SimpleFile = ({ value, onChange, defaultFileName, name, fileAcceptedTypes }: ISimpleFileProps) => {
  const [fileName, setFileName] = useState(defaultFileName);
  const [error, setError] = useState(null);

  const [t] = useTranslation();

  const onDrop = useCallback((acceptedFiles: File[]) => {
    const [file] = acceptedFiles;

    if (size(fileAcceptedTypes) > 0 && !find(fileAcceptedTypes, (ext) => endsWith(file.name, ext))) {
      setError(t(`errors.fileFormatNotAllowed`, { formats: join(fileAcceptedTypes, `, `) }));

      return;
    }

    setError(null);

    onChange(file);
  }, []);

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  useEffect(() => {
    if (value instanceof File) {
      setFileName(value.name);
    }
  }, [value]);

  useEffect(() => {
    if (defaultFileName && !fileName) {
      setFileName(defaultFileName);
    }
  }, [defaultFileName]);

  let downloadBtn;
  if (isString(value)) {
    downloadBtn = (
      <a className="link-button file__download-btn" download={true} target="_blank" href={value}>
        <img src={fileDownloadIcon} alt="" width="20" />
      </a>
    );
  } else if (value instanceof File) {
    downloadBtn = (
      <button
        className="file__download-btn"
        type="button"
        onClick={() => downloadFile({ data: value, fileName: value.name, type: value.type })}
      >
        <img src={fileDownloadIcon} alt="" width="20" />
      </button>
    );
  }

  const uploadBtnText = value ? `common.replaceFile` : `common.upload`;

  return (
    <div className="d-flex flex-column w-100">
      <div className="file">
        <div className="file__info">
          {downloadBtn}
          {!!fileName && <span className="file__filename">{fileName}</span>}
        </div>
        <div className="file__controls btn btn-primary" {...getRootProps()}>
          {t(uploadBtnText)}
          <input id={name} className="d-none" {...getInputProps()} />
        </div>
      </div>
      {!!error && <div className="text-danger mt-10 mb-10">{error}</div>}
    </div>
  );
};

export default SimpleFile;
