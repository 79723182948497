import React, { ComponentType, ReactNode, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import map from 'lodash/map';
import classNames from 'classnames';
import { FieldArrayFieldsProps } from 'redux-form';
import { IFormField } from 'types/form';

export interface IMultiArrayFieldsCallbackParams {
  fields: (value: any, index?: number) => IFormField[];
  title?: string;
  disableCallback?: (fields: FieldArrayFieldsProps<any>) => boolean;
  disabled?: boolean;
  wrapClassName?: string;
  name: string;
  showAddButton?: boolean;
  showDeleteButtons?: boolean;
}

export interface IResultCallbackParams {
  fields: FieldArrayFieldsProps<any>;
}

export type ArrayFieldsResult = (params: IResultCallbackParams) => ReactNode;

export type MultiArrayReturn = (params: IMultiArrayFieldsCallbackParams) => ArrayFieldsResult;

const useMultiArrayFields = (FormField: ComponentType<IFormField>): MultiArrayReturn => {
  const [t] = useTranslation();

  return useCallback(
    ({
        fields: inputs,
        title,
        wrapClassName,
        disabled,
        name,
        disableCallback,
        showAddButton = true,
        showDeleteButtons = true,
      }) =>
      ({ fields }) => {
        let creatingBtnIsDisabled = disabled;
        if (!creatingBtnIsDisabled && disableCallback) {
          creatingBtnIsDisabled = disableCallback(fields);
        }

        return (
          <div className="mb-15">
            <div className="flex space-between align-items-center mb-10">
              {title && <span className="font-weight-bold">{t(title)}</span>}
              {showAddButton && (
                <button
                  className="btn btn-alt-success"
                  type="button"
                  disabled={creatingBtnIsDisabled}
                  id={`${name}_add_btn`}
                  onClick={() => fields.push({})}
                >
                  {t(`common.add`)}
                </button>
              )}
            </div>
            {fields.map((field, index) => {
              return (
                <div key={index} className={classNames(wrapClassName, `flex space-between mb-10 align-items-start`)}>
                  {map(inputs(fields.get(index), index), (input, key) => {
                    return (
                      <FormField {...input} arrayField key={key} formGroup={false} name={`${field}.${input.name}`} />
                    );
                  })}
                  {showDeleteButtons && (
                    <button
                      className="ml-10"
                      type="button"
                      id={`${name}_${index}_remove_btn`}
                      onClick={() => {
                        fields.remove(index);
                      }}
                    >
                      <i className="fa fa-close text-danger font-size-xl mt-7" />
                    </button>
                  )}
                </div>
              );
            })}
          </div>
        );
      },
    []
  );
};

export default useMultiArrayFields;
