import { useState, useCallback } from 'react';
import useDeepCompareEffect from 'react-use/lib/useDeepCompareEffect';
import useUnmount from 'react-use/lib/useUnmount';
import isString from 'lodash/isString';
import some from 'lodash/some';
import assign from 'lodash/assign';
import isEmpty from 'lodash/isEmpty';
import has from 'lodash/has';
import { Dispatch } from 'redux';

import { limitProvider, searchProvider } from 'utils/pagination';
import { IFilterSearchParams, IUseListParams, IWidgetsState } from 'types/widgets';
import useWidgetScrollPagination from 'hooks/widgets/useWidgetScrollPagination';

interface IDefaultQueryParamsParams {
  withPagination: boolean;
  search: IFilterSearchParams;
  perPage: number;

  filterFormName?: string;
  defaultQueryParams?: Record<string, unknown>;
  loadOnlyFilters?: string[];
}

interface IDefaultQueryParamsResult {
  queryParams: Record<string, unknown>;
  errorWasFound: boolean;
}

const getDefaultQueryParams = ({
  withPagination,
  defaultQueryParams,
  search,
  filterFormName,
  loadOnlyFilters,
  perPage,
}: IDefaultQueryParamsParams): IDefaultQueryParamsResult => {
  let queryParams = {};
  let errorWasFound = false;

  if (!isEmpty(defaultQueryParams)) {
    assign(queryParams, defaultQueryParams);
  }

  if (withPagination) {
    const { page = 1, limit = perPage } = search || {};

    assign(queryParams, { limit: limitProvider(limit), page });
  }

  if (isString(filterFormName)) {
    if (!isEmpty(loadOnlyFilters)) {
      if (some(loadOnlyFilters, (filter) => has(search, filter))) {
        assign(queryParams, searchProvider(search));
      } else {
        errorWasFound = true;
      }
    } else {
      assign(queryParams, searchProvider(search));
    }
  }

  if (errorWasFound) {
    queryParams = {};
  }

  return { queryParams, errorWasFound };
};

export interface IUseTableParams extends IUseListParams {
  widgetState: IWidgetsState;
  perPage: number;
  search: IFilterSearchParams;

  filterFormName?: string;
  withPagination?: boolean;
  statusBar?: boolean;
  loadOnMount?: boolean;
  loadOnlyFilters?: string[];
}

export interface IUseTableResult<ListItem = any> {
  state: IWidgetsState<ListItem>;
  dispatch: Dispatch;
}

const useTable = <ListItem = any>({
  widgetState,
  perPage,
  dispatch,
  actionCreators: { getList, resetError, resetList },
  filterFormName,
  queryParams: defaultQueryParams,
  withPagination = false,
  resetOnUnmount = true,
  loadOnlyIfNecessary = false,
  statusBar = false,
  loadOnMount = true,
  loadOnlyFilters,
  disableLoading = false,
  search,
}: IUseTableParams): IUseTableResult<ListItem> => {
  const loadList = useCallback(
    (params, meta) => {
      if (!disableLoading && getList) {
        dispatch(getList(params, meta));
      }
    },
    [disableLoading, getList]
  );

  const [queryParams, setQueryParams] = useState(
    getDefaultQueryParams({
      withPagination,
      defaultQueryParams,
      search,
      filterFormName,
      loadOnlyFilters,
      perPage,
    }).queryParams
  );
  const [mounted, setMounted] = useState(false);

  useDeepCompareEffect(() => {
    const { queryParams, errorWasFound } = getDefaultQueryParams({
      withPagination,
      defaultQueryParams,
      search,
      filterFormName,
      loadOnlyFilters,
      perPage,
    });

    if (errorWasFound) {
      dispatch(resetList());
    } else {
      setQueryParams(queryParams);
    }
  }, [search, defaultQueryParams, loadOnlyFilters]);

  useUnmount(() => {
    if (resetError) {
      if (resetOnUnmount) {
        dispatch(resetList());
      }
      dispatch(resetError());
    }
  });

  useDeepCompareEffect(() => {
    let load = true;
    if ((!mounted && !loadOnMount) || (loadOnlyIfNecessary && widgetState.items)) {
      load = false;
    }

    if (load) {
      loadList(queryParams, { statusBar });
    }

    if (!mounted) {
      setMounted(true);
    }
  }, [queryParams]);

  useWidgetScrollPagination({
    disable: perPage !== 0 || !withPagination || disableLoading,
    widgetState,
    loadList,
    queryParams,
  });

  return { state: widgetState, dispatch };
};

export default useTable;
