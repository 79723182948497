import React, { useRef, useState, useCallback, useMemo, ReactNode } from 'react';
import classNames from 'classnames';
import { Portal } from 'react-portal';
import useClickAway from 'react-use/lib/useClickAway';
import { useKey } from 'react-use';

import { getCoordsOfElem, pressEsc } from 'utils';
import { Position } from 'utils/enums';

export interface IDropdownProps {
  children: ReactNode;
  insideContent: (onClose?: () => void) => ReactNode;
  className?: string;
}

const Dropdown = ({ insideContent, children, className }: IDropdownProps) => {
  const ref = useRef(null);
  const refPortal = useRef(null);
  const [showPortal, setShowPortal] = useState(false);

  const [position, positionStyle] = useMemo(() => {
    const { right = 0, left = 0, bottom = 0 } = getCoordsOfElem(ref.current);

    const isLeftPosition = right < window.innerWidth / 2;

    const position = isLeftPosition ? Position.LEFT : Position.RIGHT;
    const positionStyle = {
      left: isLeftPosition ? left : right,
      top: bottom + document.documentElement.scrollTop,
    };

    return [position, positionStyle];
  }, [showPortal]);

  const onClick = useCallback(() => {
    setShowPortal(!showPortal);
  }, [showPortal]);

  const onClose = useCallback(() => {
    setShowPortal(false);
  }, []);

  let refClickAway = false;
  useClickAway(ref, () => (refClickAway = true));
  useClickAway(refPortal, () => {
    if (refClickAway) {
      setShowPortal(false);
    }
  });

  useKey(
    pressEsc,
    () => {
      if (showPortal) {
        setShowPortal(false);
      }
    },
    { event: `keyup` },
    [showPortal]
  );

  return (
    <div className={classNames(`dropdown-wrap`, className)} ref={ref} onClick={onClick}>
      {children}
      {showPortal && (
        <Portal>
          <div className="dropdown-body" style={positionStyle} ref={refPortal} onClick={(e) => e.stopPropagation()}>
            <div className={classNames(`dropdown-elem`, `dropdown-elem--${position}`)}>{insideContent(onClose)}</div>
          </div>
        </Portal>
      )}
    </div>
  );
};

export default Dropdown;
