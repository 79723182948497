import React, { ComponentType, useCallback } from 'react';
import { Dispatch } from 'redux';
import { useTranslation } from 'react-i18next';
import { InjectedFormProps } from 'redux-form';
import ReactPaginate from 'react-paginate';
import classNames from 'classnames';
import get from 'lodash/get';
import toNumber from 'lodash/toNumber';
import { useMount } from 'react-use';
import { push } from 'connected-react-router';

//@ts-ignore
import { limitProvider } from 'utils/pagination';
//@ts-ignore
import { updateSearchParams } from 'utils';
//@ts-ignore
import { setTablesPerPage } from 'actions/auth';
//@ts-ignore
import { IFilterSearchParams, IWidgetActionCreators } from 'types/widgets';
//@ts-ignore
import { IFormField } from 'types/form';
//@ts-ignore
import { InputType } from 'utils/enums';

interface IFormData {
  perPage: string | number;
  page: string | number;
}

export interface IPaginatorProps {
  actionCreators: IWidgetActionCreators;
  pageCount: number;
  page: number;
  loading: boolean;
  dispatch: Dispatch;
  location: Location;
  // eslint-disable-next-line @typescript-eslint/ban-types
  paginatorValues: object;
  search: IFilterSearchParams;
  FormField: ComponentType<IFormField>;

  perPage?: number;
}

export interface IPaginatorOwnProps extends InjectedFormProps<IFormData>, IPaginatorProps {}

const Paginator = ({
  pageCount,
  page,
  perPage,
  actionCreators,
  change,
  loading,
  initialize,
  dispatch,
  location,
  paginatorValues,
  search,
  FormField,
}: IPaginatorOwnProps) => {
  const [t] = useTranslation();

  useMount(() => {
    // eslint-disable-next-line prefer-const
    let { page = 1, limit = perPage } = search;

    const actualPerPage = limitProvider(limit as string | number, 0);
    page = toNumber(page);
    page = page || 1;

    dispatch(setTablesPerPage(actualPerPage));
    dispatch(actionCreators.setPage(page));

    initialize({
      page,
      perPage: actualPerPage,
    });
  });

  const onPerPageChange = useCallback(
    (value) => {
      change(`page`, 1);

      dispatch(setTablesPerPage(value));
      dispatch(actionCreators.setPage(1));

      const payload = {
        ...search,
        limit: value,
        page: 1,
      };

      dispatch(push(updateSearchParams(location, payload)));

      localStorage.setItem(`perPage`, value);
    },
    [change, actionCreators, location, search]
  );

  const updatePage = useCallback(
    (page) => {
      const payload = {
        ...search,
        page,
      };

      dispatch(actionCreators.setPage(page));
      dispatch(push(updateSearchParams(location, payload)));
    },
    [actionCreators, location, search]
  );

  const onPageChange = useCallback(
    ({ selected }) => {
      const page = selected + 1;

      updatePage(page);
    },
    [change, updatePage]
  );

  const onPageInputSubmit = useCallback(
    (e) => {
      e.preventDefault();

      let page = get(paginatorValues, `page`);
      if (!page) {
        return;
      }
      page = toNumber(page);
      if (page < 1) {
        return;
      }

      updatePage(page);
    },
    [updatePage, paginatorValues]
  );

  return (
    <div>
      <form className="row p-20">
        <div className="col-xl-3">
          {/*@ts-ignore*/}
          <FormField
            name="perPage"
            label={t(`widgets.linesPerPage`)}
            className="per-page-select"
            type={InputType.SELECT}
            data={[
              {
                value: 0,
                text: t(`common.all`),
              },
              {
                value: 20,
                text: `20`,
              },
              {
                value: 50,
                text: `50`,
              },
              {
                value: 100,
                text: `100`,
              },
            ]}
            onChange={onPerPageChange}
            disabled={loading}
            isPaginatorField
          />
        </div>
        {perPage !== 0 && (
          <div className={classNames(`col-xl-5 p-0 paginator__wrap`, { paginator__loading: loading })}>
            <button
              type="button"
              className={classNames(`paginator__end-btn`, { disabled: page === 1 })}
              disabled={page === 1}
              onClick={() => updatePage(1)}
            >
              <i className="fa fa-angle-double-left" />
            </button>
            {/*@ts-ignore*/}
            <ReactPaginate
              previousLabel={<i className="fa fa-angle-left" />}
              nextLabel={<i className="fa fa-angle-right" />}
              forcePage={page - 1}
              pageCount={pageCount}
              marginPagesDisplayed={1}
              pageRangeDisplayed={3}
              onPageChange={onPageChange}
              containerClassName="paginator"
              activeClassName="active"
              disabledClassName="disabled"
              disableInitialCallback={true}
            />
            <button
              type="button"
              className={classNames(`paginator__end-btn`, { disabled: page >= pageCount })}
              disabled={page >= pageCount}
              onClick={() => updatePage(pageCount)}
            >
              <i className="fa fa-angle-double-right" />
            </button>
          </div>
        )}
        {perPage !== 0 && (
          <div className="col-xl-4 flex">
            {/*@ts-ignore*/}
            <FormField
              name="page"
              label={t(`widgets.goToPage`)}
              className="page-field"
              normalize={(value: any) => {
                value = value.replace(/[^\d]/g, ``);
                if (value < 1) return null;

                return value;
              }}
              disabled={loading}
              isPaginatorField
            />
            <button className="btn btn-alt-primary ml-10" onClick={onPageInputSubmit} disabled={loading}>
              {t(`widgets.goTo`)}
            </button>
          </div>
        )}
      </form>
    </div>
  );
};

export default Paginator;
