export enum InputType {
  SELECT = `select`,
  MUI_SELECT = `mui-select`, // Todo: SELECT
  TEXT = `text`,
  MUI_TEXT = `mui-text`,
  MULTI_SELECT = `multi-select`,
  MUI_MULTI_SELECT = `mui-multi-select`,
  PASSWORD = `password`,
  MUI_PASSWORD = `mui-password`, // Todo: PASSWORD
  MUI_CHECKBOX = `mui-checkbox`, // Todo: CHECKBOX
  CHECKBOX = `checkbox`,
  RADIO = `radio`,
  TEXTAREA = `textarea`,
  TEXT_EDITOR = `text-editor`,
  DATE_RANGE = `date-range`,
  DATE_TIME_PICKER = `date-time-picker`,
  INPUT_TITLE = `input-title`,
  MUI_DATE_RANGE = `mui-date-range`,
  TIME_RANGE = `time-range`,
  DATE = `date`,
  MUI_DATE = `mui-date`,
  DROPZONE = `dropzone`, // Todo: бывший file
  FILE = `file`,
  CARD_NUMBER = `card-number`, // Todo: бывший cardNumber
  CARD_DATE = `card-date`, // Todo: бывший cardDate
  SIMPLE_FILE = `simple-file`,
  PHONE_NUMBER = `phone-number`,
  MAC_ADDRESS = `mac-address`,
}

export enum RangeDatepickerInput {
  FROM = `from`,
  TO = `to`,
}

export enum FieldType {
  INPUT = 1,
  CHECKBOX_GROUP,
  FIELD_ARRAY,
}

export enum Position {
  LEFT = `left`,
  TOP = `top`,
  BOTTOM = `bottom`,
  RIGHT = `right`,
}

export enum Size {
  MINI = 1,
  LITTLE,
  MIDDLE,
  BIG,
}

export enum GridType {
  FLEX = 1,
  GRID,
  LIST,
}

export enum AuthStatus {
  LOGGED_IN,
  CHECKING,
  GUEST,
}

export enum WidgetOperations {
  LIST_LOADING = `listLoading`,
  ITEM_LOADING = `itemLoading`,
  ITEM_CREATING = `itemCreating`,
  LIST_ITEM_UPDATING = `listItemUpdating`,
  ITEM_UPDATING = `itemUpdating`,
  ITEM_DELETING = `itemDeleting`,
  MULTI_DELETING = `multiDeleting`,
}

export enum ResponseStatus {
  SUCCESS = `success`,
  ERROR = `error`,
}
