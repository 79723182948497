import React, { useEffect, useState } from 'react';
import get from 'lodash/get';
import find from 'lodash/find';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useTranslation } from 'react-i18next';

import useDropdownTranslator from 'hooks/form/useDropdownTranslator';
import { ISelectData } from 'types/form';

export interface IMaterialUIDropdownSelectProps {
  id: string | number;
  value: string | number;
  onChange: (val: string | number | null) => void;

  data?: ISelectData[];
  className?: string;
  translateKeys?: boolean;
  placeholder?: string;
  busy?: boolean;
  error?: boolean;
  optionClassName?: (item: ISelectData) => string;
}

const MaterialUIDropdownSelect = ({
  data,
  onChange,
  translateKeys,
  optionClassName,
  placeholder,
  busy: loading,
  value,
  error,
  ...props
}: IMaterialUIDropdownSelectProps) => {
  const [t] = useTranslation();

  const [inputValue, setInputValue] = useState<string>(``);
  const [actualValue, setActualValue] = useState<ISelectData | null>(null);

  const list = useDropdownTranslator(data, translateKeys);

  useEffect(() => {
    const currentItemData = find(list, { value });
    if (currentItemData) {
      setActualValue(currentItemData);
      setInputValue(get(currentItemData, `text`, ``));
    } else {
      setInputValue(``);
      setActualValue(null);
    }
  }, [value, list]);

  return (
    <Autocomplete
      value={actualValue}
      options={list}
      getOptionLabel={(item) => get(item, `text`, ``)}
      renderOption={(item) => (
        <div className={optionClassName ? optionClassName(item) : undefined} key={item.value}>
          {item.text}
        </div>
      )}
      inputValue={inputValue}
      onInputChange={(e, value) => {
        if (get(e, `target.value`) === value) {
          setInputValue(value);
        } else {
          setInputValue(``);
        }
      }}
      onChange={(event, item) => {
        onChange(get(item, `value`, null));
      }}
      noOptionsText={t(`common.listIsEmpty`)}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            error={error}
            placeholder={placeholder}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading && <CircularProgress color="inherit" size={20} />}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        );
      }}
      loading={loading}
    />
  );
};

MaterialUIDropdownSelect.defaultProps = {
  data: [],
  className: ``,
  translateKeys: false,
  optionClassName: () => ``,
  busy: false,
  error: false,
};

export default MaterialUIDropdownSelect;
