import React, { ComponentType, ReactNode, useEffect } from 'react';
import classNames from 'classnames';
import map from 'lodash/map';
import get from 'lodash/get';
import each from 'lodash/each';
import assign from 'lodash/assign';
import toNumber from 'lodash/toNumber';
import { InjectedFormProps } from 'redux-form';
import { useTranslation } from 'react-i18next';

import { sleep } from 'utils';
import { IFilterSearchParams } from 'types/widgets';
import { GridType, InputType } from 'utils/enums';
import { IFormField } from 'types/form';

import RangeDatepicker from 'components/form/inputs/datepicker/RangeDatePicker';
import MaterialUIRangeDataPicker from 'components/form/inputs/datepicker/MaterialUIRangeDataPicker';

interface IFieldMappingParams {
  values: Record<string, unknown>;
  FormField: ComponentType<IFormField>;
  ReduxFormField: ComponentType<any>;
}

const getFieldMapping =
  ({ values, FormField, ReduxFormField }: IFieldMappingParams) =>
  (field: IFormField, key: number): ReactNode | null => {
    if (!field || field?.hasAccess === false) return null;

    const fieldId = get(field, `name`, key);

    if (field.type === InputType.DATE_RANGE) {
      const date_range = get(values, fieldId);

      const fromValue = get(date_range, `values.startDate`) || field.fromValue;
      const toValue = get(date_range, `values.endDate`) || field.toValue;

      return (
        <div key={fieldId} className="filter-form-date">
          <FormField {...field} name={field.name} withFormGroupClass={false} fromValue={fromValue} toValue={toValue} />
        </div>
      );
    }

    if (field.type === InputType.MUI_DATE_RANGE) {
      const date_range = get(values, `date_range`);

      return (
        <div key={fieldId} className="filter-form-date">
          <ReduxFormField
            {...field}
            name={field.name}
            component={MaterialUIRangeDataPicker}
            fromValue={get(date_range, `values.startDate`)}
            toValue={get(date_range, `values.endDate`)}
          />
        </div>
      );
    }

    return (
      <div className="filter-form-input" key={fieldId}>
        <FormField {...field} />
      </div>
    );
  };

interface IDateFieldInfo {
  from: string;
  to: string;
  name: string;
}

export interface IFiltersProps extends InjectedFormProps<any> {
  submit: () => void; // This is a deprecated method of reduxForm
  FormField: ComponentType<IFormField>;
  values: Record<string, unknown>;
  searchParams: IFilterSearchParams;
  ReduxFormField: ComponentType<any>;

  rightFields?: IFormField[];
  leftFields?: IFormField[];
  dateFields?: IDateFieldInfo[];
  numFields?: string[];
  gridType?: GridType;
  submitText?: string;
  displayResetBtn?: boolean;
  disabled?: boolean;
  // eslint-disable-next-line @typescript-eslint/ban-types
  defaultParams?: object;
  submissionControlsOnTheRightSide?: boolean;
  className?: string;
  onReset?: () => void;
  useSearchParamsAsDefaultParams?: boolean;
}

const Filters = ({
  handleSubmit,
  initialize,
  submit,
  rightFields,
  leftFields,
  dateFields,
  gridType,
  numFields,
  className,
  submitText,
  displayResetBtn,
  disabled,
  defaultParams,
  submissionControlsOnTheRightSide,
  FormField,
  values,
  searchParams,
  ReduxFormField,
  onReset,
  useSearchParamsAsDefaultParams,
}: IFiltersProps) => {
  const [t] = useTranslation();

  useEffect(() => {
    const params = { ...defaultParams, ...searchParams };
    each(dateFields, ({ from, to, name }) => {
      if (from && to && name) {
        const searchFrom = get(params, from);
        const searchTo = get(params, to);
        if (searchFrom && searchTo) {
          delete params[from];
          delete params[to];
          assign(params, { [name]: { values: { startDate: new Date(searchFrom), endDate: new Date(searchTo) } } });
        }
      }
    });
    each(numFields, (field) => {
      if (params[field]) {
        assign(params, { [field]: toNumber(params[field]) });
      }
    });
    initialize(params);
  }, [searchParams]);

  const handleReset = async () => {
    const defaultReset = async () => {
      initialize(useSearchParamsAsDefaultParams ? { ...defaultParams } : {});
      await sleep(100);
      submit();
    };

    onReset ? onReset() : defaultReset();
  };

  const controls = (
    <div className="filter-form-controls">
      <button className="btn btn-secondary" disabled={disabled} id="filters_submit_btn">
        {t(submitText as string)}
      </button>
      {displayResetBtn && (
        <button className="btn btn-secondary ml-10" onClick={handleReset} type="button" id="filters_reset_btn">
          {t(`common.reset`)}
        </button>
      )}
    </div>
  );

  return (
    <div className="table-filter-header table-control-panel">
      <form
        className={classNames(`table-filter-form`, className, {
          'table-filter-form--flex': gridType === GridType.FLEX,
          'table-filter-form--grid': gridType === GridType.GRID,
          'table-filter-form--list': gridType === GridType.LIST,
        })}
        onSubmit={handleSubmit}
      >
        {leftFields && (
          <div className="table-filter-left">
            {map(leftFields, getFieldMapping({ values, FormField, ReduxFormField }))}
            {!submissionControlsOnTheRightSide && controls}
          </div>
        )}
        <div className="table-filter-right">
          {map(rightFields, getFieldMapping({ values, FormField, ReduxFormField }))}
          {submissionControlsOnTheRightSide && controls}
        </div>
      </form>
    </div>
  );
};

Filters.defaultProps = {
  rightFields: [],
  leftFields: [],
  dateFields: [],
  numFields: [],
  gridType: GridType.FLEX,
  submitText: `common.filterSubmit`,
  displayResetBtn: true,
  disabled: false,
  defaultParams: {},
  submissionControlsOnTheRightSide: true,
};

export default Filters;
