import React, { ComponentType, ReactNode, useState } from 'react';
import map from 'lodash/map';
import { useTranslation } from 'react-i18next';

import { INavListData } from 'types/nav';

interface INavItemProps extends INavListData {
  hideSidebar: () => void;
  ReactNavLink: ComponentType<any>;
}

const NavItem = ({ title, path, icon, hasAccess, onClick, children, hideSidebar, ReactNavLink }: INavItemProps) => {
  const [opened, setOpened] = useState(false);

  const [t] = useTranslation();

  if (!hasAccess) {
    return null;
  }

  let content = (
    <>
      {icon && <i className={icon} />}
      {t(title)}
    </>
  );

  let innerList;
  if (children && opened) {
    innerList = (
      <ul className="header__mobile-secondary-menu">
        {map(children, (item, key) => (
          <NavItem {...item} key={key} hideSidebar={hideSidebar} ReactNavLink={ReactNavLink} />
        ))}
      </ul>
    );
  }

  if (!children) {
    if (path) {
      content = (
        <ReactNavLink to={path} activeClassName="active" onClick={hideSidebar}>
          {content}
        </ReactNavLink>
      );
    } else if (onClick) {
      content = (
        <button onClick={onClick} className="header-primary-btn">
          {content}
        </button>
      );
    }
  } else {
    content = (
      <button onClick={() => setOpened(!opened)} className="header-primary-btn">
        {content}
      </button>
    );
  }

  return (
    <li>
      {content}
      {innerList}
    </li>
  );
};

export interface ISidebarProps {
  hideSidebar: () => void;
  list: INavListData[];
  logo: ReactNode;
  ReactNavLink: ComponentType<any>;
}

const Sidebar = ({ hideSidebar, list, logo, ReactNavLink }: ISidebarProps) => {
  return (
    <nav id="sidebar">
      <div id="sidebar-scroll">
        <div className="sidebar-content">
          <div className="content-header content-header-fullrow bg-black-op-10">
            <div className="content-header-section text-center align-parent">
              <button
                type="button"
                className="btn btn-circle btn-dual-secondary d-lg-none align-v-r"
                onClick={hideSidebar}
              >
                <i className="fa fa-times text-danger" />
              </button>
              <div className="content-header-item">{logo}</div>
            </div>
          </div>
          <div className="content-side content-side-full">
            <ul className="nav-main mobile-menu">
              {map(list, (item, key) => (
                <NavItem {...item} key={key} hideSidebar={hideSidebar} ReactNavLink={ReactNavLink} />
              ))}
            </ul>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Sidebar;
