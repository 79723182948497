import React, { useState, useEffect, useCallback, HTMLProps, ChangeEvent } from 'react';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import get from 'lodash/get';
import map from 'lodash/map';
import toNumber from 'lodash/toNumber';
import { WrappedFieldInputProps } from 'redux-form/lib/Field';
import TimeField from 'react-simple-timefield';
import Input from '@material-ui/core/Input';

import { range } from 'utils';
import { IDatepickerProps } from 'types/form';
import i18n from '../../../../i18n';

export interface IDatepickerOwnProps extends IDatepickerProps {
  input: WrappedFieldInputProps;

  inputClassName?: string;
  showTimeSelect?: boolean;
  value?: Date | null;
  clearValueWithNoDate?: boolean;
}

interface IHeaderParams {
  date: Date;
  changeYear(year: number): void;
  changeMonth(month: number): void;
  decreaseMonth(): void;
  increaseMonth(): void;
  prevMonthButtonDisabled: boolean;
  nextMonthButtonDisabled: boolean;
  decreaseYear(): void;
  increaseYear(): void;
  prevYearButtonDisabled: boolean;
  nextYearButtonDisabled: boolean;
}

const TimeInput = (props: HTMLProps<HTMLInputElement>) => (
  <input {...props} className="timepicker__input form-control" />
);

const Datepicker = ({
  input,
  minDate,
  maxDate = new Date(),
  inputClassName,
  showTimeSelect,
  value,
  clearValueWithNoDate = true,
}: IDatepickerOwnProps) => {
  const [startDate, setStartDate] = useState<Date | null | undefined>(value);

  const [time, setTime] = useState<string>(startDate ? moment(startDate).format(`HH:mm`) : `00:00`);

  const years = range(moment(minDate).year(), moment(maxDate).year());
  const months = moment.localeData(i18n.language).months();

  useEffect(() => {
    const date = get(input, `value`);

    if (date) {
      setStartDate(moment(date).toDate());
      setTime(moment(date).format(`HH:mm`));
    } else if (clearValueWithNoDate) {
      setTime(`00:00`);
      setStartDate(null);
    }
  }, [input]);

  const onTimeChange = useCallback((time) => {
    const momentTime = moment(time, `HH:mm`);
    const newDate = moment(startDate).set({ h: momentTime.get(`hour`), m: momentTime.get(`minute`) });
    setTime(time);
    input.onChange(newDate);
  }, []);

  const onDateChange = useCallback((date: Date) => {
    setStartDate(moment(date).toDate());
    input.onChange(moment(date));
  }, []);

  const calendarHeader = ({
    date,
    changeYear,
    changeMonth,
    decreaseMonth,
    increaseMonth,
    prevMonthButtonDisabled,
    nextMonthButtonDisabled,
  }: IHeaderParams) => (
    <div className="rdrMonthAndYearWrapper">
      <button
        type="button"
        className="rdrNextPrevButton rdrPprevButton"
        onClick={decreaseMonth}
        disabled={prevMonthButtonDisabled}
      >
        <i />
      </button>
      <span className="rdrMonthAndYearPickers">
        <span className="rdrMonthPicker">
          <select
            value={months[moment(date).month()]}
            onChange={({ target: { value } }) => changeMonth(months.indexOf(value))}
          >
            {map(months, (option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
        </span>
        <span className="rdrYearPicker">
          <select value={moment(date).year()} onChange={({ target: { value } }) => changeYear(toNumber(value))}>
            {map(years, (option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
        </span>
      </span>
      <button
        type="button"
        className="rdrNextPrevButton rdrNextButton"
        onClick={increaseMonth}
        disabled={nextMonthButtonDisabled}
      >
        <i />
      </button>
    </div>
  );

  return (
    <DatePicker
      selected={startDate}
      onChange={onDateChange}
      dateFormat={showTimeSelect ? `dd-MM-yyyy HH:mm` : `dd-MM-yyyy`}
      className={inputClassName}
      minDate={moment(minDate).toDate()}
      maxDate={moment(maxDate).toDate()}
      portalId="root-portal"
      renderCustomHeader={calendarHeader}
      showTimeInput={showTimeSelect}
      customInput={<Input />}
      timeInputLabel={``}
      customTimeInput={
        <div className="timepicker__wrap">
          <TimeField
            value={time}
            onChange={(e: ChangeEvent<HTMLInputElement>, val: string) => onTimeChange(val)}
            input={<TimeInput />}
          />
        </div>
      }
    />
  );
};

export default Datepicker;
