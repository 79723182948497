import React, { ComponentType, HTMLProps, ReactNode } from 'react';
import map from 'lodash/map';
import size from 'lodash/size';
import classNames from 'classnames';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { AnyAction, Dispatch } from 'redux';

import useTable from 'hooks/widgets/useTable';
import { IFilterSearchParams, IWidgetActionCreators, IWidgetModal, IWidgetsState, IWidgetTitles } from 'types/widgets';
import { IModalData } from 'types/common';
import { ITableContainerProps } from 'types/components';
import { IFormField } from 'types/form';

import PageContainer from 'components/containers/PageContainer';
import WidgetContainer from 'components/containers/WidgetContainer';
import WidgetTable from 'components/widgets/WidgetTable';
import Modal from 'components/modals/Modal';
import TableContentContainer from 'components/containers/TableContentContainer';
import { IPaginatorProps } from 'components/tables/Paginator';

interface IExtraButton extends HTMLProps<HTMLButtonElement> {
  onClick: () => void | AnyAction;
  title: string;

  withDispatch?: boolean;
  buttonClassName?: string;
}

export interface IWidgetProps<Item = any> extends ITableContainerProps {
  widgetState: IWidgetsState;
  actionCreators: IWidgetActionCreators;
  pageTitle: string;
  tableTitle: string | ReactNode;
  tableHeadTitles: IWidgetTitles[];
  listMapping: (item: any, key: number) => ReactNode;
  dispatch: Dispatch;
  perPage: number;
  listLoading: boolean;
  items: Item[] | null;
  widget: string;
  hiddenColumns: string[];
  location: Location;
  paginatorValues: Record<string, unknown>;
  modal: IWidgetModal;
  search: IFilterSearchParams;
  FormField: ComponentType<IFormField>;
  ReduxFormPaginator: ComponentType<IPaginatorProps>;
  header: ReactNode;
  routeTabs: ReactNode;

  filterFormName?: string;
  withPagination?: boolean;
  statusBar?: boolean;
  loadListOnMount?: boolean;
  loadOnlyFilters?: string[];
  resetOnUnmount?: boolean;
  headTitle?: string;

  controlPanel?: ReactNode;

  extraButtons?: IExtraButton[];
  extraTableHeaderContent?: ReactNode;
  modals?: IModalData[];
  filterForm?: ReactNode;
}

function Widget<Item = any>({
  widgetState,
  actionCreators,
  pageTitle,
  tableTitle,
  tableHeadTitles,
  listMapping,
  modals,
  extraButtons,
  filterForm,
  filterFormName,
  extraHeaderButtons,
  withPagination,
  statusBar,
  loadListOnMount,
  loadOnlyFilters,
  extraHeaderContent,
  translatedTitle,
  resetOnUnmount,
  headTitle,
  refreshingIsDisabled,
  extraTableHeaderContent,
  controlPanel,
  tableHeaderContent,
  perPage,
  listLoading,
  items,
  dispatch,
  widget,
  hiddenColumns,
  location,
  paginatorValues,
  modal,
  search,
  FormField,
  header,
  ReduxFormPaginator,
  routeTabs,
}: IWidgetProps<Item>) {
  const {
    state: { paginationLoading, lastPage, page },
  } = useTable({
    widgetState,
    actionCreators,
    filterFormName,
    withPagination,
    statusBar,
    loadOnMount: loadListOnMount,
    loadOnlyFilters,
    resetOnUnmount,
    dispatch,
    perPage,
    listLoading,
    items,
    search,
  });

  const [t] = useTranslation();

  return (
    <>
      <PageContainer>
        {headTitle && (
          <Helmet>
            <title>{t(headTitle)}</title>
          </Helmet>
        )}
        {header}
        <WidgetContainer title={t(pageTitle)}>
          {routeTabs}
          <TableContentContainer
            perPage={perPage}
            dispatch={dispatch}
            title={tableTitle}
            onRefresh={actionCreators.getList}
            loading={listLoading}
            extraHeaderButtons={extraHeaderButtons}
            extraHeaderContent={extraHeaderContent}
            translatedTitle={translatedTitle}
            refreshingIsDisabled={refreshingIsDisabled}
            tableHeaderContent={tableHeaderContent}
            search={search}
            withPagination={withPagination}
          >
            {(size(extraButtons) > 0 || extraTableHeaderContent) && (
              <div className="pb-20 table-control-panel flex flex-wrap">
                {map(
                  extraButtons,
                  ({ title, onClick, withDispatch = true, buttonClassName = `btn-alt-primary`, id }, key) => {
                    const clickHandler = () => {
                      if (withDispatch) {
                        dispatch(onClick() as AnyAction);
                      } else {
                        onClick();
                      }
                    };

                    return (
                      <button key={key} id={id} className={classNames(`btn`, buttonClassName)} onClick={clickHandler}>
                        {t(title)}
                      </button>
                    );
                  }
                )}
                {extraTableHeaderContent}
              </div>
            )}
            {controlPanel && <div className="pb-20 table-control-panel">{controlPanel}</div>}
            {filterForm}
            <WidgetTable
              FormField={FormField}
              dispatch={dispatch}
              hiddenColumns={hiddenColumns}
              widget={widget}
              data={items}
              titles={tableHeadTitles}
              listMapping={listMapping}
              paginationLoading={paginationLoading}
              lastPage={lastPage}
              page={page}
              perPage={perPage}
              loading={listLoading}
              actionCreators={actionCreators}
              withPagination={withPagination}
              // @ts-ignore
              location={location}
              paginatorValues={paginatorValues}
              search={search}
              ReduxFormPaginator={ReduxFormPaginator}
            />
          </TableContentContainer>
        </WidgetContainer>
      </PageContainer>
      {map(modals, (data, key) => (
        <Modal key={key} {...data} modal={modal} />
      ))}
    </>
  );
}

Widget.defaultProps = {
  filterFormName: null,
  withPagination: false,
  statusBar: false,
  loadListOnMount: true,

  extraButtons: [],
  modals: [],
  filterForm: null,
  extraHeaderButtons: [],

  translatedTitle: true,
};

export default Widget;
