import { MutableRefObject, useEffect } from 'react';
import isNull from 'lodash/isNull';

export interface IScrollPaginationParams {
  page: number;
  callback: () => void;

  extraPixels?: number;
  timeout?: number;
  disable?: boolean;
  ref?: MutableRefObject<any> | null;
}

const useScrollPagination = ({
  page,
  callback,
  extraPixels = 200,
  timeout = 200,
  disable = false,
  ref = null,
}: IScrollPaginationParams) => {
  if (!page || !callback) {
    throw new Error(`Not all required settings in useScrollPagination`);
  }

  useEffect(() => {
    if (disable) {
      return;
    }
    let timer: any;

    const onScrollAsync = () => {
      clearTimeout(timer);

      timer = setTimeout(() => {
        const elem = isNull(ref) ? document.documentElement : ref.current;

        if (elem) {
          const { scrollTop, clientHeight, scrollHeight } = elem;

          if (scrollHeight - scrollTop - clientHeight <= extraPixels) {
            callback();
          }
        }
      }, timeout);
    };

    if (isNull(ref) || isNull(ref.current)) {
      document.addEventListener(`scroll`, onScrollAsync);

      return () => document.removeEventListener(`scroll`, onScrollAsync);
    } else {
      ref?.current.addEventListener(`scroll`, onScrollAsync);

      return () => ref?.current.removeEventListener(`scroll`, onScrollAsync);
    }
  }, [page, disable, callback, ref, extraPixels, timeout]);
};

export default useScrollPagination;
