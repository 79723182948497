import toNumber from 'lodash/toNumber';
import isNumber from 'lodash/isNumber';
import isString from 'lodash/isString';

import { DEFAULT_PAGINATION_LIMIT, SCROLL_PAGINATION_LIMIT } from 'utils/constants';
import availablePaginationLimits from 'static/pagination';
import { IAnyObject } from 'types/common';

export const limitProvider = (limit: string | number, defaultValue = SCROLL_PAGINATION_LIMIT): number => {
  if (!isString(limit) && !isNumber(limit)) {
    return DEFAULT_PAGINATION_LIMIT;
  }

  limit = toNumber(limit);

  return availablePaginationLimits.has(limit) ? limit : defaultValue;
};

export interface ISearchProviderParams {
  perPage?: number | string;
}

export const searchProvider = (search: IAnyObject, { perPage }: ISearchProviderParams = {}): IAnyObject => {
  const { limit = perPage, ...otherSearchParams } = search;
  const limitParam = limit ? { limit: limitProvider(limit) } : {};

  return { ...otherSearchParams, ...limitParam };
};
