import React, { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import classNames from 'classnames';
import find from 'lodash/find';
import isNumber from 'lodash/isNumber';
import endsWith from 'lodash/endsWith';
import isNull from 'lodash/isNull';
import split from 'lodash/split';
import head from 'lodash/head';
import size from 'lodash/size';
import join from 'lodash/join';
import some from 'lodash/some';
import each from 'lodash/each';
import map from 'lodash/map';
import floor from 'lodash/floor';
import isFunction from 'lodash/isFunction';
import isString from 'lodash/isString';
import isObject from 'lodash/isObject';
import isEmpty from 'lodash/isEmpty';
// @ts-ignore
import { Player } from 'video-react';
import { useTranslation } from 'react-i18next';

import { sleep } from 'utils';
import { IDropzoneProps } from 'types/form';

import uploadIcon from 'assets/media/upload.png';

const imageFileTypes = [`.png`, `.jpeg`, `.jpg`, `.svg`, `.bmp`, `.gif`];

const videoFileTypes = [`mp4`, `m4v`, `mov`, `mpeg`, `mpg`];

const isType = (types: string[], fileName: string): boolean => some(types, (ext) => endsWith(fileName, ext));

const getPureFileName = (fileName: string): string => head(split(fileName, `?`, 1)) as string;

export interface IDropzoneOwnProps extends IDropzoneProps {
  onChange: (files?: File | File[]) => void;
  name: string;

  className?: string;
  containerClassName?: string;
  defaultValue?: string | File;
}

interface IReadFileParams {
  approvedFiles?: File[];
}

// Todo: component will be deprecated
const Dropzone = ({
  onChange,
  name,
  className,
  containerClassName,
  defaultValue,
  maxSize,
  fileAcceptedTypes,
  deleteFileHandler,
  dropFileHandler,
  showDeleteButton,
  multiple,
  errorCallback,
  nameValidation,
  nameMask,
}: IDropzoneOwnProps) => {
  const [file, setFile] = useState<string | null>(null);
  const [fileType, setFileType] = useState<string | null>(null);
  const [prevDefaultValue, setPrevDefaultValue] = useState<File | string | null>(null);
  const [error, setError] = useState<string | null>(null);

  const [t] = useTranslation();

  const readFileCallback = useCallback((file: File, { approvedFiles }: IReadFileParams = {}) => {
    const reader = new FileReader();

    reader.onload = () => {
      if (isType([...imageFileTypes, ...videoFileTypes], file.name)) {
        let type;
        if (endsWith(file.name, `.svg`)) {
          type = `image/svg+xml`;
          setFileType(`image`);
        } else if (isType(videoFileTypes, file.name)) {
          type = `video/*`;
          setFileType(`video`);
        } else {
          type = `image/*`;
          setFileType(`image`);
        }
        const blob = new Blob([reader.result as BlobPart], { type });
        const url = URL.createObjectURL(blob);
        setFile(url);
      } else {
        if (multiple) {
          if (approvedFiles) {
            approvedFiles.push(file);
            setFile(
              join(
                map(approvedFiles, ({ name }) => name),
                `; `
              )
            );
          }
        } else {
          setFile(file.name);
        }
        setFileType(null);
      }
      if (dropFileHandler) {
        dropFileHandler(file);
      }
      sleep(100).then(() => {
        if (multiple) {
          onChange(approvedFiles);
        } else {
          onChange(file);
        }
      });
    };

    reader.readAsArrayBuffer(file);
  }, []);

  useEffect(() => {
    if (!defaultValue) {
      setFileType(null);
      setFile(null);
    } else if (isNull(prevDefaultValue) && defaultValue && !file) {
      if (isObject(defaultValue)) {
        if (!isEmpty(defaultValue)) {
          readFileCallback(defaultValue);
        }
      } else {
        const isImage = !!some(imageFileTypes, (ext) => {
          return endsWith(getPureFileName(defaultValue), ext);
        });
        if (isImage) {
          setFileType(`image`);
        }
        const isVideo = !!some(videoFileTypes, (ext) => {
          return endsWith(getPureFileName(defaultValue), ext);
        });
        if (isVideo) {
          setFileType(`video`);
        }
        setFile(defaultValue);
        setPrevDefaultValue(defaultValue);
      }
    }
  }, [defaultValue]);

  useEffect(() => {
    if (errorCallback) {
      errorCallback(error);
    }
  }, [error]);

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      const validate = (file: File) => {
        if (nameValidation && isFunction(nameValidation) && !nameValidation(file.name)) {
          setError(t(`errors.fileNameDontMatchPattern`, { pattern: nameMask }));

          return false;
        }
        if (size(fileAcceptedTypes) > 0 && !find(fileAcceptedTypes, (ext) => endsWith(file.name, ext))) {
          setError(t(`errors.fileFormatNotAllowed`, { formats: join(fileAcceptedTypes, `, `) }));

          return false;
        }

        if (isNumber(maxSize) && file.size > maxSize) {
          const size = maxSize > 1024 * 1024 ? floor(maxSize / 1024 / 1024) : floor(maxSize / 1024);
          const postfix = maxSize > 1024 * 1024 ? `mb` : `kb`;
          setError(t(`errors.maxFileSize`, { size, postfix }));

          return false;
        }

        return true;
      };

      const readFile = (file: File, params?: IReadFileParams) => {
        if (error) {
          setError(null);
        }
        readFileCallback(file, params);
      };

      if (multiple) {
        const errorWasOccurred = some(acceptedFiles, (file) => !validate(file));
        if (errorWasOccurred) {
          return;
        }

        const approvedFiles: File[] = [];
        each(acceptedFiles, (file) => {
          readFile(file, { approvedFiles });
        });
      } else {
        const [file] = acceptedFiles;
        if (!validate(file)) {
          return;
        }
        readFile(file);
      }
    },
    [error, dropFileHandler]
  );
  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  let content = null;
  if (error) {
    content = <span className="text-error">{error}</span>;
  } else if (file) {
    if (fileType === `image`) {
      content = <img src={file} alt="" className="dropzone__image" />;
    } else if (fileType === `video`) {
      content = (
        <Player className="dropzone__video">
          <source src={file} />
        </Player>
      );
    } else if (isString(file)) {
      content = <span className="dropzone__file-names">{file}</span>;
    }
  } else {
    content = (
      <div className="dropzone__cloud-wrap">
        <img src={uploadIcon} alt="" className="dropzone__cloud" />
        <span>{t(`common.upload`)}</span>
      </div>
    );
  }

  return (
    <div className={classNames(containerClassName, `dropzone__wrap`)}>
      {showDeleteButton && file && (
        <div className="dropzone__control-panel">
          <button
            onClick={() => {
              setFile(null);
              setFileType(null);
              if (deleteFileHandler) {
                deleteFileHandler();
              }
            }}
          >
            <i className="fa fa-trash" />
          </button>
        </div>
      )}
      <div
        className={classNames(
          className,
          `dropzone`,
          { 'dropzone--empty': !file && !error },
          { 'dropzone--with-control-panel': showDeleteButton && file }
        )}
        {...getRootProps()}
      >
        {content}
        <input name={name} disabled={fileType === `video`} className="hidden" {...getInputProps()} />
      </div>
    </div>
  );
};

Dropzone.defaultProps = {
  defaultValue: null,
  maxSize: 1024 * 1024 * 5,
  fileAcceptedTypes: [],
  showDeleteButton: true,
  multiple: false,
};

export default Dropzone;
