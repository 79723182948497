import React, { useState, useEffect, useCallback } from 'react';
import map from 'lodash/map';
import filter from 'lodash/filter';
import { WrappedFieldInputProps } from 'redux-form/lib/Field';

import { updateHiddenColumnRequested } from 'actions/auth';
import { ICheckboxGroupData } from 'types/form';

import CheckboxGroup from 'components/form/inputs/checkbox/CheckboxGroup';
import { Dispatch } from 'redux';

export interface ITableColumnHideFormProps {
  widget: string;
  columns: ICheckboxGroupData<string>[];
  hiddenColumns: string[];
  dispatch: Dispatch;
}

const TableColumnHideForm = ({ widget, columns, hiddenColumns, dispatch }: ITableColumnHideFormProps) => {
  const [value, setValue] = useState<string[]>([]);

  useEffect(() => {
    const activeColumns = filter(columns, (item) => !hiddenColumns.includes(item.value));
    setValue(map(activeColumns, (item) => item.value));
  }, [hiddenColumns]);

  const handleCheckbox = useCallback(
    (val) => {
      const hidden = filter(columns, (item) => !val.includes(item.value));
      dispatch(
        updateHiddenColumnRequested({
          name: widget,
          columns: map(hidden, (item) => item.value),
        })
      );
      setValue(val);
    },
    [columns, widget]
  );

  return (
    <div className="table-column-hide-form">
      <CheckboxGroup
        checkAll
        input={
          {
            value,
            onChange: handleCheckbox,
            name: `table-columns`,
          } as WrappedFieldInputProps
        }
        data={columns}
        labelClassName="table-column-hide-form-label"
      />
    </div>
  );
};

export default TableColumnHideForm;
