import { RefObject } from 'react';
import isArray from 'lodash/isArray';

import useScrollPagination from 'hooks/useScrollPagination';
import { SCROLL_PAGINATION_LIMIT } from 'utils/constants';
import { IWidgetsMeta, IWidgetsState } from 'types/widgets';
import { IAnyObject } from 'types/common';

export interface IWidgetScrollPaginationParams {
  widgetState: IWidgetsState;
  loadList: (payload?: Record<string, unknown>, meta?: IWidgetsMeta) => void;

  queryParams?: Record<string, unknown>;
  disable?: boolean;
  ref?: RefObject<any>;
}

const useWidgetScrollPagination = ({
  disable = false,
  widgetState,
  queryParams = {},
  loadList,
  ref,
}: IWidgetScrollPaginationParams) => {
  useScrollPagination({
    disable: !widgetState.nextPage || disable,
    callback: () => {
      if (!widgetState.listLoading || !loadList) {
        const payload: IAnyObject = {
          ...queryParams,
          limit: SCROLL_PAGINATION_LIMIT,
          page: widgetState.page + 1,
        };

        if (isArray(widgetState.createdItems)) {
          payload.excluded_ids = [...widgetState.createdItems];
        }

        loadList(payload, { incPage: true });
      }
    },
    page: widgetState.page,
    ref,
  });
};

export default useWidgetScrollPagination;
