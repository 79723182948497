import { useEffect } from 'react';
import { Dispatch } from 'redux';
import { replace } from 'connected-react-router';

import { findDeep } from 'utils';
import { INavListData, INavListDataParams } from 'types/nav';

export interface IUserHasAccessParams {
  navListData: (params: INavListDataParams) => INavListData[];
  userPermissions: string[] | null;
  routeName: string;
  dispatch: Dispatch;
}

const useUserHasAccess = ({ navListData, userPermissions, routeName, dispatch }: IUserHasAccessParams) => {
  useEffect(() => {
    const pages = navListData({ permissions: userPermissions, dispatch, username: null });
    const currentPagePermissions = findDeep<INavListData>(pages, `path`, routeName);

    if (routeName && currentPagePermissions) {
      if (!currentPagePermissions.hasAccess) {
        dispatch(replace(`/no-permissions`));
      }
    }
  }, [routeName]);
};

export default useUserHasAccess;
