import React from 'react';
import '../../../styles/components/base/title.scss';

const Title = ({ titleText = `` }: { titleText?: string }): JSX.Element => {
  return (
    <div className="title">
      <span className="title__text">{titleText}</span>
    </div>
  );
};

export default Title;
