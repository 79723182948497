import React, { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
// @ts-ignore
import { Player } from 'video-react';
import { useTranslation } from 'react-i18next';
import last from 'lodash/last';
import split from 'lodash/split';
import some from 'lodash/some';
import isNumber from 'lodash/isNumber';
import endsWith from 'lodash/endsWith';
import isString from 'lodash/isString';
import size from 'lodash/size';
import useKeyPressEvent from 'react-use/lib/useKeyPressEvent';
import find from 'lodash/find';
import join from 'lodash/join';
import floor from 'lodash/floor';

import { downloadFile, imitateLinkClick, pressEsc } from 'utils';
import { IDropzoneProps } from 'types/form';

const getFileExtension = (fileName?: string): null | string => {
  if (!fileName) {
    return null;
  }

  return last(split(fileName, `.`) as string[]) as string;
};

const isType = (types: string[], fileName?: string): boolean => {
  if (!fileName) {
    return false;
  }

  return some(types, (ext) => endsWith(fileName, ext));
};

const imageFileTypes = [`png`, `jpeg`, `jpg`, `svg`, `bmp`, `gif`];

const videoFileTypes = [`mp4`, `m4v`, `mov`, `mpeg`, `mpg`];

export interface IFileInputProps extends IDropzoneProps {
  onChange: (files?: File | File[]) => void;
  name: string;

  value?: string | File | File[];
}

const FileInput = ({ onChange, name, value, defaultFileName, fileAcceptedTypes, maxSize }: IFileInputProps) => {
  const [t] = useTranslation();

  const [fileName, setFileName] = useState(defaultFileName);
  const [bigPictureMode, setBigPictureMode] = useState(false);
  const [file, setFile] = useState<string | undefined>(defaultFileName);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (defaultFileName) {
      setFileName(defaultFileName);
      setFile(value as string | undefined);
    }
  }, [defaultFileName]);

  const readFileCallback = useCallback((file = {}) => {
    const reader = new FileReader();
    reader.onload = () => {
      if (isType([...imageFileTypes, ...videoFileTypes], file.name)) {
        let type;
        if (getFileExtension(file.name) === `svg`) {
          type = `image/svg+xml`;
        } else if (isType(imageFileTypes, file.name)) {
          type = `image/*`;
        } else if (isType(videoFileTypes, file.name)) {
          type = `video/*`;
        }
        const blob = new Blob([reader.result as BlobPart], { type });
        const url = URL.createObjectURL(blob);
        setFile(url);
      }
      setFileName(file.name);
      onChange(file);
    };
    reader.readAsArrayBuffer(file);
  }, []);

  const onFileDownload = useCallback(() => {
    if (value instanceof window.File && fileName) {
      let type;
      if (isType(imageFileTypes, value?.name)) {
        type = `image/*`;
      } else if (isType(videoFileTypes, value?.name)) {
        type = `video/*`;
      } else {
        type = null;
      }
      downloadFile({ data: value, fileName, type });
    } else if (isString(value) && size(value)) {
      imitateLinkClick(value, { openInNewTab: true });
    }
  }, [value]);

  const validate = useCallback((file) => {
    if (size(fileAcceptedTypes) > 0 && !find(fileAcceptedTypes, (ext) => endsWith(file.name, ext))) {
      setError(t(`errors.fileFormatNotAllowed`, { formats: join(fileAcceptedTypes, `, `) }));

      return false;
    }

    if (isNumber(maxSize) && file.size > maxSize) {
      const size = maxSize > 1024 * 1024 ? floor(maxSize / 1024 / 1024) : floor(maxSize / 1024);
      const postfix = maxSize > 1024 * 1024 ? `mb` : `kb`;
      setError(t(`errors.maxFileSize`, { size, postfix }));

      return false;
    }

    return true;
  }, []);

  const readFile = useCallback(
    (file) => {
      if (error) {
        setError(null);
      }
      readFileCallback(file);
    },
    [error]
  );

  const onDrop = useCallback(
    (acceptedFiles) => {
      const [file] = acceptedFiles;
      if (!validate(file)) {
        return;
      }

      readFile(file);
    },
    [error]
  );

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  const openBigPicture = useCallback(() => {
    setBigPictureMode(true);
  }, []);
  const hideBigPicture = useCallback(() => {
    setBigPictureMode(false);
  }, []);

  useKeyPressEvent(pressEsc, hideBigPicture);

  let preview;
  let bigPicture;
  if (isType(imageFileTypes, fileName)) {
    preview = <img onClick={openBigPicture} src={file} />;
    bigPicture = <img className="file__modal-content" src={file} />;
  } else if (isType(videoFileTypes, fileName)) {
    preview = <video className="video-player" onClick={openBigPicture} src={file} />;
    bigPicture = (
      <Player className="dropzone__video">
        <source src={file} />
      </Player>
    );
  } else {
    preview = <span className="font-weight-bold">.{getFileExtension(fileName)}</span>;
  }

  return (
    <div className="file__common-wrap">
      <div className="file">
        <div className="file__info">
          <div className="file__preview">{preview}</div>
          {!!fileName && <span className="file__filename">{fileName}</span>}
        </div>
        <div className="file__actions-wrap">
          {!!value && (
            <button type="button" onClick={onFileDownload} className="file__download-btn">
              <i className="fa fa-download" aria-hidden="true" />
            </button>
          )}
          <div className="file__controls btn" {...getRootProps()}>
            {t(`logs.uploadFile`)}
            <input id={name} className="d-none" {...getInputProps()} />
          </div>
        </div>
      </div>
      {error && <div className="file__error">{error}</div>}
      <div className={`modal file__modal-img ${bigPictureMode ? `d-block` : `d-none`}`}>
        <button type="button" onClick={hideBigPicture} className="file__close-modal">
          &times;
        </button>
        {bigPicture}
      </div>
    </div>
  );
};

export default FileInput;
