import React, { ChangeEvent, useEffect, useState } from 'react';
import includes from 'lodash/includes';
import map from 'lodash/map';
import filter from 'lodash/filter';
import size from 'lodash/size';
import sortBy from 'lodash/sortBy';
import toLower from 'lodash/toLower';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { WrappedFieldInputProps, WrappedFieldMetaProps } from 'redux-form/lib/Field';

import useIsTouchDevice from 'hooks/useIsTouchDevice';
import useToggle from 'hooks/useToggle';
import i18n from '../../../../i18n';
import { ICheckboxGroup, ICheckboxGroupData } from 'types/form';

type OnChange = (val: number[] | string[]) => void;

interface IChangeHandler {
  value: number | string;
  values: number[] | string[];
  onChange: OnChange;
}

const changeHandler =
  ({ value, values, onChange }: IChangeHandler) =>
  (e: ChangeEvent<HTMLInputElement>) => {
    const arr = e.target.checked ? [...values, value] : filter(values, (item) => item !== value);

    onChange(arr as number[] | string[]);
  };

export interface ICheckboxGroupProps extends ICheckboxGroup {
  input?: WrappedFieldInputProps;
  meta?: WrappedFieldMetaProps;
}

const CheckboxGroup = ({
  title,
  data,
  input,
  grouped,
  groupWrapClassName,
  labelClassName,
  checkAll,
  sort,
  filterBySearch,
}: ICheckboxGroupProps) => {
  const [displayedData, setDisplayedData] = useState<ICheckboxGroupData[] | undefined>(data);
  const [search, setSearch] = useState(``);

  const [t] = useTranslation();

  const { isOpened, onToggle } = useToggle({ hideOnEsc: false });

  const isTouchDevice = useIsTouchDevice();

  useEffect(() => {
    let newData = data;

    if (filterBySearch) {
      newData = filter(newData, ({ text }) => includes(toLower(text), toLower(search)));
    }

    if (sort) {
      newData = sortBy(newData, ({ value }) => !includes(input?.value, value));
    }

    setDisplayedData(newData);
  }, [input?.value, data, search, filterBySearch, sort]);

  const labelClassNames = classNames(
    `checkbox-group__label css-control css-control-success css-switch css-control-sm`,
    labelClassName
  );

  let checkAllLabel;
  if (checkAll) {
    checkAllLabel = (
      <label className={labelClassNames}>
        <div className="flex flex-align-center checkbox-group__label-text">
          <span className="mr-5">{t(`common.all`)}</span>
        </div>
        <input
          type="checkbox"
          className="css-control-input"
          checked={size(data) === size(input?.value)}
          onChange={(e) => {
            if (e.target.checked) {
              input?.onChange(map(data, ({ value }) => value));
            } else {
              input?.onChange([]);
            }
          }}
        />
        <span className="css-control-indicator" />
      </label>
    );
  }

  const list = map(displayedData, ({ text, value, disabled, description, ruDescription }) => {
    let displayingDescription = ruDescription || description;
    displayingDescription = i18n.language === `ru` ? displayingDescription : description;
    let descriptionItem;
    if (displayingDescription) {
      if (isTouchDevice) {
        descriptionItem = <p className="text-muted font-size-sm">{displayingDescription}</p>;
      } else {
        descriptionItem = <i className="fa fa-question-circle-o mt-1 ml-5" title={displayingDescription} />;
      }
    }

    return (
      <label key={value} className={labelClassNames}>
        <div className="flex flex-align-center checkbox-group__label-text">
          <span className="text-break-word">
            {text}
            {descriptionItem}
          </span>
        </div>
        <input
          type="checkbox"
          disabled={disabled}
          className="css-control-input"
          checked={includes(input?.value, value)}
          onChange={changeHandler({
            value,
            values: input?.value,
            onChange: input?.onChange as OnChange,
          })}
        />
        <span className="css-control-indicator" />
      </label>
    );
  });

  if (grouped) {
    return (
      <div className="checkbox-group form-group block block-bordered block-rounded mb-10">
        <div className="block-header checkbox-group__group-title" onClick={onToggle}>
          <span className="font-weight-bold">{title}</span>
        </div>
        {isOpened && (
          <div className={classNames(`p-10`, groupWrapClassName)}>
            {checkAllLabel}
            {list}
          </div>
        )}
      </div>
    );
  }

  return (
    <div className="checkbox-group form-group">
      <span className="checkbox-group__title block font-weight-bold">{title}</span>
      {filterBySearch && (
        <input
          type="text"
          className="form-control w-100 mt-10 mb-10"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          placeholder={t(`common.search`)}
        />
      )}
      {checkAllLabel}
      {list}
    </div>
  );
};

export default CheckboxGroup;
