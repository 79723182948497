import { createSelector } from 'reselect';
import get from 'lodash/get';
import { IRootState } from 'types/common';
import { IFormsState, ISingleFormState } from 'types/form';

export const formSelector = (state: IRootState): IFormsState => state?.form;

export const formByFormNameSelector = (formName: string) =>
  createSelector<IRootState, IFormsState, ISingleFormState | undefined>(formSelector, (form) => get(form, formName));

export const formUnusedSelector = (formName: string) =>
  createSelector<IRootState, ISingleFormState | undefined, string[]>(formByFormNameSelector(formName), (form) =>
    get(form, `unused`, [])
  );
