import React, { FunctionComponent, useState, useEffect } from 'react';
import { DecoratedComponentClass } from 'redux-form';
import { replace } from 'connected-react-router';
import { Dispatch } from 'redux';
import every from 'lodash/every';
import reduce from 'lodash/reduce';
import includes from 'lodash/includes';
import map from 'lodash/map';
import last from 'lodash/last';

const Status = {
  REQUESTED: 1,
  ACCEPTED: 2,
  DENIED: 3,
};

interface IPermission {
  permission: string;
  opposite: boolean;
}

interface IRedirectData {
  redirectUrl: string;
  permissions: IPermission[];
}

type IWithPermissionCheckProps = <T = any>(
  permissionsList: string[],
  dispatch: Dispatch
) => (
  Component: FunctionComponent | DecoratedComponentClass<any, any>,
  redirectData: IRedirectData[]
) => (props: T) => JSX.Element | null;

const withPermissionCheck: IWithPermissionCheckProps =
  (permissionsList, dispatch) =>
  (Component, redirectData = []) =>
  (props) => {
    const [status, setStatus] = useState(Status.REQUESTED);
    const [redirectPath, setRedirectPath] = useState(``);

    useEffect(() => {
      if (status === Status.DENIED) {
        dispatch(replace(redirectPath));
      }
    }, [status, redirectPath, dispatch]);

    useEffect(() => {
      const redirectPathCollection = reduce(
        redirectData,
        (result: string[], { permissions, redirectUrl }) => {
          const permissionGroup = map(permissions, ({ permission, opposite }) => {
            const hasPermission = includes(permissionsList, permission);

            return opposite ? !hasPermission : hasPermission;
          });

          return every(permissionGroup, (condition) => condition) ? [...result, redirectUrl] : result;
        },
        []
      );

      const path = last(redirectPathCollection);

      if (path) {
        setStatus(Status.DENIED);
        setRedirectPath(path);
      } else {
        setStatus(Status.ACCEPTED);
      }
    }, [redirectData, setStatus, setRedirectPath]);

    return status === Status.ACCEPTED ? <Component {...props} /> : null;
  };

export default withPermissionCheck;
